import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonInputItem = (props: any) => (
  <ContentLoader
    speed={2}
    width={750}
    height={120}
    viewBox="0 0 750 120"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="250" height="15" />
    <rect x="0" y="25" rx="2" ry="2" width="750" height="100" />
  </ContentLoader>
);

export default SkeletonInputItem;
