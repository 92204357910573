import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Menu } from 'antd';

import Icon from 'icons';
import PAGES from 'routes/constants';
import { logout } from 'stores/auth/auth.reducer';
import { useUser } from 'stores/auth/auth.selector';

const PopoverProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useUser();

  const handleSignout = () => {
    dispatch(logout());
    navigate(PAGES.login);
  };

  const menuTransaction = [
    {
      key: 'output-history',
      label: (
        <Link to={PAGES.content} className="d-flex-align-center">
          <Icon name="time" />
          All outputs History
        </Link>
      ),
    },
    {
      key: 'billing',
      label: (
        <Link to={PAGES.billingSetting} className="d-flex-align-center">
          <Icon name="billing" />
          Billing
        </Link>
      ),
    },
    { key: 'divider', type: 'divider' },
    {
      key: 'signout',
      label: (
        <div className="d-flex-align-center" onClick={handleSignout}>
          <Icon name="signout" />
          Sign out
        </div>
      ),
    },
  ];

  return (
    <div className="popover-profile__content">
      <div className="popover-profile__top">
        <Icon name="default-avatar" />
        {user && (
          <div className="profile-wrapper">
            <p className="username">
              {user?.profile?.fullName
                ? user?.profile?.fullName
                : `${user?.profile?.firstName} ${user?.profile?.lastName}`}
            </p>
            <Link to={PAGES.profileSetting}>Edit Profile</Link>
          </div>
        )}
      </div>
      <div className="popover-profile__body">
        <Menu selectedKeys={[]} mode="vertical" items={menuTransaction} />
      </div>
    </div>
  );
};

export default PopoverProfile;
