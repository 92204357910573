import React from 'react';
import moment from 'moment';
import { Avatar, Row, Col, Button } from 'antd';
import ColorHash from 'color-hash';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Icon from 'icons';
import Legend from 'components/common/legend';
import Loading from 'components/common/loading';
import API from 'services/api';
import QUERY_KEYS from 'services/api/queryKeys';
import { getChatURL } from 'routes/constants';
import { getStandForCharacter, convertYoutubeEmbed } from 'utils';
import './index.scss';

const colorHash = new ColorHash();

const RecipesDetail = () => {
  const navigate = useNavigate();
  const { recipeId } = useParams();

  const { data: recipeDetail, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_RECIPES, recipeId],
    queryFn: () => API.recipe.getRecipeDetail(String(recipeId)),
  });

  const handleRunRecipe = (data: string) => {
    navigate(getChatURL(data));
  };

  const standforCharacter = React.useMemo(() => {
    if (recipeDetail) {
      return getStandForCharacter(recipeDetail?.owner.name);
    }
  }, [recipeDetail]);

  const videoUrl = React.useMemo(() => {
    if (recipeDetail) {
      return convertYoutubeEmbed(String(recipeDetail?.videoUrl));
    }
  }, [recipeDetail]);

  if (isLoading) {
    return (
      <div className="block-spinner">
        <Loading />
      </div>
    );
  }

  return (
    <div className="recipes-community">
      <article>
        <div className="recipes-community__top">
          <div className="avatar-wrapper">
            <Avatar
              style={{
                backgroundColor: colorHash.hex(String(standforCharacter)),
                color: '#fff',
              }}
            >
              {standforCharacter}
            </Avatar>
          </div>
          <div className="recipe-top">
            <h1>{recipeDetail?.name}</h1>
            <p>
              Created by {recipeDetail?.owner.name} on{' '}
              {moment(recipeDetail?.createdAt).format('MMM D')}
            </p>
          </div>
        </div>

        {/* Action mobile */}
        <div className="action-wrapper-mobile">
          <Button className="btn-save">
            <Icon name="save" />
            16.3k
          </Button>
          <Button className="btn-gradient btn-run">
            <Icon name="play" />
            Run
          </Button>
        </div>

        <div className="recipes-community__content">
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={16}>
              <div className="youtube-wrapper">
                <iframe
                  src={videoUrl}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={false}
                ></iframe>
              </div>
              <div style={{ marginBottom: '3rem' }}>
                {recipeDetail?.userProfile && (
                  <Legend
                    tag="Company Info"
                    content={
                      <>
                        <p>Name: {recipeDetail?.userProfile.companyName}</p>
                        <p>
                          Website:{' '}
                          <a
                            href={recipeDetail?.userProfile.website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {recipeDetail?.userProfile.website}
                          </a>
                        </p>
                      </>
                    }
                  />
                )}
              </div>
              <div>
                <Legend tag="Description" content={recipeDetail?.description} />
              </div>
              <div style={{ marginTop: '3rem' }}>
                <Legend tag="Recipe content" content={recipeDetail?.content} />
              </div>
            </Col>
            <Col xs={24} lg={8}>
              <div className="action-wrapper">
                <Button className="btn-save">
                  <Icon name="save" />
                  19.5k
                </Button>
                <Button
                  onClick={() => handleRunRecipe(recipeDetail?.content ?? '')}
                  className="btn-gradient btn-run"
                >
                  <Icon name="play" />
                  Run
                </Button>
              </div>

              {/*<div className="users-wrapper">*/}
              {/*  <Avatar size={32}>{recipeDetail?.owner.name}</Avatar>*/}
              {/*</div>*/}

              <div className="help-wrapper">
                <h3>About our Recipe Scribe!</h3>
                <ul className="link-wrapper">
                  <li>
                    <a href="#" target="_blank" rel="noreferrer">
                      Name: {recipeDetail?.owner.name}
                    </a>
                  </li>
                  {/* <li>
                    Company:{' '}
                    <a
                      href="https://app.homescribe.ai/recipes"
                      target="_blank"
                      rel="noreferrer"
                    >
                      NterNow
                    </a>
                  </li>
                  <li>
                    Website:{' '}
                    <a
                      href="https://app.homescribe.ai/recipes"
                      target="_blank"
                      rel="noreferrer"
                    >
                      www.NterNow.com
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://learn.homescribe.ai/bootcamp/recipes"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Join the Homescribe Community!
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </article>
    </div>
  );
};

export default RecipesDetail;
