import React from 'react';
import ContentLoader from 'react-content-loader';

type Props = {
  height?: number;
  width?: number;
};

const SkeletonLine = ({ width = 240, height = 40 }: Props) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="8" ry="8" width={width} height={height} />
  </ContentLoader>
);

export default SkeletonLine;
