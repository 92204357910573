import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonBtnGenerate = (props: any) => (
  <ContentLoader
    speed={2}
    width={122}
    height={60}
    viewBox="0 0 122 60"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="8" ry="8" width="122" height="60" />
  </ContentLoader>
);

export default SkeletonBtnGenerate;
