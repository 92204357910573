const PAGES = {
  home: '/',
  login: '/login',
  signup: '/signup',
  onboarding: '/onboarding',
  templates: '/templates',
  templateDetail: '/templates/:templateId',
  chat: '/chat',
  documents: '/docs',
  addDocument: '/docs/new',
  documentTrash: '/docs/trash',
  documentDetail: '/docs/edit/:documentId',
  projects: '/projects',
  recipes: '/recipes',
  recipeDetail: '/recipes/:recipeId',
  communityRecipes: '/community-recipes',
  recipeEdit: '/recipes/edit/:recipeId',
  recipeNew: '/recipes/new',
  help: '/help',

  settings: '/settings',
  billingSetting: '/settings/billing',
  profileSetting: '/settings/profile',
  integrationSetting: '/settings/integration',
  generalSetting: '/settings/general',
  teamSetting: '/settings/team',
  usageSetting: '/settings/usage',
  onboardingSetting: '/settings/onboarding',

  content: '/content',
  integration: '/settings/integration',
  comingSoon: '/coming-soon',
};

export const PATTERN_URL = {
  TEMPLATE: `/${PAGES.templates}/:templateId`,
  SETTING: `/${PAGES.settings}/*`,
};

export const getDocumentDetailURL = (documentId: string | number) => {
  return `${PAGES.documents}/edit/${documentId}`;
};

export const getRecipeCommunityURL = (recipeId: string | number) => {
  return `${PAGES.recipes}/${recipeId}`;
};

export const getRecipeDetailURL = (recipeId: string | number) => {
  return `${PAGES.recipes}/edit/${recipeId}`;
};

export const getChatURL = (recipe: string | number) => {
  return `${PAGES.chat}?recipe=${recipe}`;
};

export default PAGES;
