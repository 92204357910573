import React from 'react';
import { Outlet } from 'react-router-dom';

import './index.scss';

const PureLayout = () => {
  return (
    <div className="setting-content">
      <Outlet />
    </div>
  );
};

export default PureLayout;
