import React from 'react';
import { Button } from 'antd';
import './index.scss';
import Icon from 'icons';

type Props = {
  outputItem: any;
};

const OutputItem = ({ outputItem }: Props) => {
  return (
    <div className="output-item">
      <div className="output-item__action-wrapper">
        <div className="output-item__action">
          <Button className="btn-action btn-favorite">
            <Icon name="star" />
          </Button>

          <Button className="btn-action">
            <Icon name="copy" />
          </Button>

          <Button className="btn-action">
            <Icon name="document-v2" />
          </Button>

          <Button className="btn-action">
            <Icon name="thumb" />
          </Button>

          <Button className="btn-action">
            <Icon name="down-thumb" />
          </Button>
        </div>
        <div className="output-item__created">
          <span>13m ago</span>
        </div>
      </div>

      <div className="output-item__content">{outputItem?.text || ''}</div>
    </div>
  );
};

export default OutputItem;
