export const CATEGORY_OPTIONS = [
  {
    value: 'sales',
    label: 'Sales',
  },
  {
    value: 'marketing',
    label: 'Marketing',
  },
  {
    value: 'executive',
    label: 'Executive',
  },
  {
    value: 'operations',
    label: 'Operations',
  },
];
