import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Form,
  Input,
  Tooltip,
  Switch,
  Select,
  Radio,
  RadioChangeEvent,
} from 'antd';

import Icon from 'icons';
import Editor from 'components/common/editor';
import ClickOutSide from 'components/common/click-outside';
import Loading from 'components/common/loading';
import PAGES from 'routes/constants';
import API from 'services/api';
import QUERY_KEYS from 'services/api/queryKeys';
import { Deltas, Document } from 'services/api/type/document.type';

import './index.scss';

const MAX_TAGS = 3;

const DocumentDetail = () => {
  const [form] = Form.useForm();
  const { documentId } = useParams();
  const navigate = useNavigate();
  const inputRef = useRef<any>();
  const [title, setTitle] = useState('');
  const [isShowInput, setIsShowInput] = useState(false);
  const [, setIsShowConfig] = useState<any>({
    isShowContent: false,
    isShowToneOfVoice: false,
    isShowKeyword: false,
  });
  const [outputLength, setOutputLength] = useState('s');
  const keywords = Form.useWatch('keywords', form);
  const { data: document, isLoading } = useQuery<Document>({
    queryKey: [QUERY_KEYS.GET_DOCUMENT_DETAIL, documentId],
    queryFn: () => API.document.getDocumentDetail(String(documentId)),
  });

  useEffect(() => {
    if (document?.name) {
      setTitle(document.name);
    }
    form.setFieldsValue({
      topic: document?.topic || '',
      tone: document?.tone || '',
      keywords: document?.keywords || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const handleBack = () => {
    navigate(PAGES.documents);
  };

  const handleClickOutSideInput = () => {
    if (isShowInput) {
      setIsShowInput(false);
    }
  };

  const handleShowInput = () => {
    setIsShowInput(true);
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 0);
  };

  const handleChangeTag = () => {
    const currentKeywords = form.getFieldValue('keywords');
    if (currentKeywords?.length > 3) {
      currentKeywords.pop();
      form.setFieldValue('keywords', currentKeywords);
    }
  };

  const handleToggleConfig = (name: string, value: boolean) => {
    setIsShowConfig({
      ...setIsShowConfig,
      [name]: value,
    });
  };

  const handleChangeOutput = (e: RadioChangeEvent) => {
    setOutputLength(e.target.value);
  };

  const handleEditorChange = (data: Deltas) => {
    console.log(data);
  };

  if (isLoading) {
    return (
      <div className="fixed-spinner">
        <Loading />
      </div>
    );
  }

  return (
    <div className="document-detail-page">
      <div className="document-detail__top">
        <div className="document-detail__top-left">
          <Button className="btn-back" onClick={handleBack}>
            <Icon name="arrow-left" />
          </Button>
          <div className="document-detail__top-left__title">
            {isShowInput ? (
              <ClickOutSide onClickoutSide={handleClickOutSideInput}>
                <Input
                  className="input-outline"
                  ref={inputRef}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </ClickOutSide>
            ) : (
              <Button className="btn-title" onClick={handleShowInput}>
                {title || 'Untitled document'}
              </Button>
            )}
          </div>
        </div>
      </div>

      <aside className="document-detail__aside">
        <div className="document-detail__aside__content">
          <Form layout="vertical" form={form}>
            <div className="form-inner">
              {/* Content */}
              <Form.Item
                name="topic"
                label={
                  <div className="form-item-label">
                    <span>Content description / brief</span>
                    <Tooltip
                      arrow={false}
                      placement="bottom"
                      title="Tell HomeScribe what you are writing about"
                    >
                      <span className="icon-info">
                        <Icon name="info" />
                      </span>
                    </Tooltip>
                    <div className="form-item-label__switch">
                      <Tooltip
                        arrow={false}
                        placement="bottom"
                        title="Toggle off to hide info from HomeScribe"
                      >
                        <Switch
                          defaultChecked
                          onChange={(value) =>
                            handleToggleConfig('isShowContent', value)
                          }
                          size="small"
                        />
                      </Tooltip>
                    </div>
                  </div>
                }
              >
                <Input.TextArea
                  id="content"
                  showCount
                  maxLength={2000}
                  placeholder="Write an article about..."
                  rows={6}
                />
              </Form.Item>

              {/* Tone of voice */}
              <Form.Item
                name="tone"
                label={
                  <div className="form-item-label">
                    <span>Tone of voice</span>
                    <Tooltip
                      arrow={false}
                      placement="bottom"
                      title="Click to learn more"
                    >
                      <span className="icon-info">
                        <Icon name="info" />
                      </span>
                    </Tooltip>
                    <div className="form-item-label__switch">
                      <Tooltip
                        arrow={false}
                        placement="bottom"
                        title="Toggle off to hide info from HomeScribe"
                      >
                        <Switch
                          defaultChecked
                          size="small"
                          onChange={(value) =>
                            handleToggleConfig('isShowToneOfVoice', value)
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                }
              >
                <Input
                  showCount
                  maxLength={60}
                  placeholder="Witty, serious, Joe Rogan, etc..."
                />
              </Form.Item>

              {/* Keywords */}
              <div>
                <Form.Item
                  name="keywords"
                  label={
                    <div className="form-item-label">
                      <span>Keywords</span>
                      <Tooltip
                        arrow={false}
                        placement="bottom"
                        title="Click to learn how keywords work"
                      >
                        <span className="icon-info">
                          <Icon name="info" />
                        </span>
                      </Tooltip>
                      <div className="form-item-label__switch">
                        <Tooltip
                          arrow={false}
                          placement="bottom"
                          title="Toggle off to hide info from HomeScribe"
                        >
                          <Switch
                            defaultChecked
                            size="small"
                            onChange={(value) =>
                              handleToggleConfig('isShowKeyword', value)
                            }
                          />
                        </Tooltip>
                      </div>
                    </div>
                  }
                >
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    onChange={handleChangeTag}
                    options={[]}
                  />
                </Form.Item>
                <span className="tag-count">
                  {keywords?.length || 0} of {MAX_TAGS}
                </span>
              </div>
            </div>
          </Form>

          <div className="document-detail__aside__output-length">
            <p className="title">Output length</p>
            <div className="output-wrapper">
              <Radio.Group
                value={outputLength}
                size="middle"
                onChange={handleChangeOutput}
              >
                <Radio.Button value="s">S</Radio.Button>
                <Radio.Button value="m">M</Radio.Button>
                <Radio.Button value="l">L</Radio.Button>
              </Radio.Group>
            </div>
          </div>
        </div>
      </aside>

      <div className="document-detail__shortcut">
        <div className="document-detail__shortcut-inner">
          <div className="document-detail__shortcut-action">
            <div>
              <Button className="btn-compose">Compose</Button>
            </div>

            <Button className="btn-reset">
              <Icon name="reset" />
            </Button>

            <div className="document-detail__shortcut__output-length">
              <p className="title">Output length</p>
              <div className="output-wrapper">
                <Radio.Group
                  value={outputLength}
                  size="middle"
                  onChange={handleChangeOutput}
                >
                  <Radio.Button value="s">S</Radio.Button>
                  <Radio.Button value="m">M</Radio.Button>
                  <Radio.Button value="l">L</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="quill-container">
        <Editor
          value={document?.deltas}
          onChange={handleEditorChange}
          placeholder="Enter text ..."
        />
      </div>
    </div>
  );
};

export default DocumentDetail;
