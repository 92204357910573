import React, { useState } from 'react';
import classnames from 'classnames';
import {
  // Link,
  useParams,
} from 'react-router-dom';
import {
  Button,
  Input,
  Form,
  // Select,
  // Tag,
  InputNumber,
  message,
} from 'antd';
import { useQuery, useMutation } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import Icon from 'icons';
import TemplateIcon from 'icons/template';
import SkeletonTitle from 'components/template-detail/skeletonTitle';
import SkeletonInputItem from 'components/template-detail/skeletonInputItem';
import SkeletonBtnGenerate from 'components/template-detail/skeletonBtnGenerate';
import SkeletonIcon from 'components/template-detail/skeletonIcon';
import SkeletonOutput from 'components/template-detail/skeletonOutput';
import NewOutputTab from 'components/template-detail/new-output-tab';
import HistoryTab from 'components/template-detail/history-tab';
// import PAGES from "routes/constants";
import QUERY_KEYS from 'services/api/queryKeys';
import API from 'services/api';
import { Template } from 'services/api/type/template.type';
import { getYoutubeThumbnail, THUMBNAIL_YOUTUBE_QUALITY } from 'utils';
import './index.scss';

enum TAB {
  OUTPUT = 1,
  HISTORY = 2,
}

const TemplateDetail = () => {
  const [form] = Form.useForm();
  const { templateId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [tab, setTab] = useState(TAB.OUTPUT);
  const [outputs, setOutputs] = useState<any[]>([]);
  const { data, isLoading } = useQuery<Template>({
    queryKey: [QUERY_KEYS.GET_TEMPLATE_DETAIL, templateId],
    queryFn: () => API.template.getTemplateDetail(String(templateId)),
  });

  const { mutateAsync, isLoading: isLoadingGenerate } = useMutation({
    mutationFn: (values: any) =>
      API.template.createCompletion(
        String(templateId),
        values,
        values.nValue || 1,
      ),
  });

  const onFinish = async (values: any) => {
    try {
      const data = await mutateAsync(values);
      setOutputs([...data, ...outputs]);
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error?.message,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleClearInput = () => {
    const resetObject = {} as any;
    (data?.inputSchema || []).forEach((item) => {
      resetObject[item.id] = '';
    });
    form.setFieldsValue(resetObject);
  };

  const handleClearOutput = () => {
    setOutputs([]);
    messageApi.open({
      type: 'success',
      content: 'Recent results cleared. They will still show up in history.',
    });
  };

  const renderContent = (Content: any, Loading: any) => {
    if (isLoading) {
      return Loading;
    }
    return Content;
  };

  const renderInputs = () => {
    if (isLoading) {
      return [...Array(4)].map((item, index) => (
        <SkeletonInputItem key={index} />
      ));
    }

    if (!isEmpty(data)) {
      const { inputSchema } = data;
      return inputSchema.map((template, index) => {
        let InputItem: any = null;
        switch (template.type) {
          case 'text':
            InputItem = Input;
            break;
          case 'textarea':
            InputItem = Input.TextArea;
            break;
          default:
            break;
        }

        return (
          <div
            className="template-detail__content__form-item"
            key={`${template.id}-${index}`}
          >
            <Form.Item
              label={template.label}
              name={template.id}
              rules={[
                {
                  required: template.required,
                  message: `Please input ${template.label}`,
                },
              ]}
            >
              <InputItem placeholder={template.placeholder} />
            </Form.Item>
          </div>
        );
      });
    }
    return null;
  };

  const renderRightSide = () => {
    if (tab === TAB.OUTPUT) {
      return <NewOutputTab outputs={outputs} />;
    }

    if (tab === TAB.HISTORY) {
      return <HistoryTab />;
    }

    return null;
  };

  const thumbnailUrl = React.useMemo(() => {
    return getYoutubeThumbnail(
      String(data?.videoUrl),
      THUMBNAIL_YOUTUBE_QUALITY.HIGH,
    );
  }, [data]);

  const btnNewOutputClass = classnames('btn-new-output', {
    selected: tab === TAB.OUTPUT,
  });
  const btnHistoryClass = classnames('btn-new-output', {
    selected: tab === TAB.HISTORY,
  });
  return (
    <div className="template-detail">
      <div className="template-detail__left">
        <Form
          form={form}
          className="template-detail__form custom-form"
          name="template-form"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="template-detail__info">
            <div className="template-detail__logo">
              {renderContent(
                <div className="template-detail__logo-inner">
                  <TemplateIcon name="aida" />
                </div>,
                <SkeletonIcon />,
              )}
            </div>
            <div className="template-detail__name">
              {renderContent(
                <>
                  <h2>{data?.name || ''}</h2>
                  <p>{data?.description}</p>
                </>,
                <SkeletonTitle />,
              )}
            </div>
          </div>

          <div
            className="template-detail__content"
            style={{ background: isLoading ? '#fff' : '' }}
          >
            {renderInputs()}

            {/* {!isLoading && (
              <section className="template-detail__translation">
                <div className="template-detail__translation__top">
                  <h3>
                    <Tag>Beta</Tag>
                    <span>Language options</span>
                    <Icon name="info" />
                  </h3>

                  <span className="template-detail__translation__top__note">
                    <span>DeepL integration is currently disabled.</span>{" "}
                    <Link to={PAGES.integration}>Enable in Settings</Link>
                  </span>
                </div>

                <div className="template-detail__translation__content">
                  <Form.Item
                    label="Input language"
                    name="inputLanguage"
                    style={{ flex: 3.6 }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      defaultValue="english"
                      // onChange={handleChange}
                      options={[{ value: "english", label: "English" }]}
                    />
                  </Form.Item>

                  <div className="arrow-right">
                    <Icon name="arrow-right" />
                  </div>

                  <Form.Item
                    label="Output language"
                    name="outputLanguage"
                    style={{ flex: 5 }}
                  >
                    <Select
                      style={{ width: "100%" }}
                      defaultValue="english"
                      options={[
                        { value: "english", label: "English (American)" },
                      ]}
                    />
                  </Form.Item>

                  <div className="formality" style={{ flex: 3.2 }}>
                    <Form.Item label="Formality" name="formality">
                      <Select
                        defaultValue="default"
                        options={[{ value: "default", label: "Default" }]}
                      />
                    </Form.Item>
                  </div>
                </div>
              </section>
            )} */}
          </div>

          <div className="template-detail__bottom">
            <div
              className="template-detail__bottom__inner"
              style={{ justifyContent: isLoading ? 'flex-end' : '' }}
            >
              {!isLoading && (
                <Button className="btn-outline" onClick={handleClearInput}>
                  <Icon name="close" /> Clear inputs
                </Button>
              )}

              <div className="template-detail__bottom__inner__action d-flex-align-center">
                {!isLoading && (
                  <Form.Item name="nValue">
                    <InputNumber defaultValue={1} max={10} min={1} />
                  </Form.Item>
                )}

                {renderContent(
                  <Button
                    loading={isLoadingGenerate}
                    className="btn-generate"
                    type="default"
                    htmlType="submit"
                  >
                    {isLoadingGenerate ? '' : 'Generate'}
                  </Button>,
                  <SkeletonBtnGenerate />,
                )}
              </div>
            </div>
          </div>
        </Form>
      </div>

      <div className="template-detail__right">
        <div className="template-detail__right__inner">
          {renderContent(
            <>
              <div className="template-detail__right__inner__top">
                <div className="btn-new-wrapper">
                  <Button
                    className={btnNewOutputClass}
                    onClick={() => setTab(TAB.OUTPUT)}
                  >
                    New outputs
                    {!!outputs.length && (
                      <span className="number-output">{outputs.length}</span>
                    )}
                  </Button>

                  <Button
                    className={btnHistoryClass}
                    onClick={() => setTab(TAB.HISTORY)}
                  >
                    History
                  </Button>
                </div>

                <Button className="btn-clear" onClick={handleClearOutput}>
                  Clear
                </Button>
              </div>

              {isEmpty(outputs) ? (
                <div className="template-detail__right__inner__content">
                  <div className="template-detail__right__video-wrapper">
                    <Button
                      className="template-detail__right__btn-video"
                      style={{ backgroundImage: `url(${thumbnailUrl})` }}
                    >
                      <span className="overlay">
                        <Icon name="play" />
                      </span>
                    </Button>
                  </div>

                  <div className="template-detail__right__description">
                    <div className="template-detail__right__description-icon">
                      <Icon name="lightning" />
                    </div>
                    <div className="template-detail__right__description-content">
                      <p className="title">Answer the prompts</p>
                      <p className="desc">
                        Get the best results by trying multiple inputs and of
                        varying lengths.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                renderRightSide()
              )}
            </>,
            <SkeletonOutput />,
          )}
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

export default TemplateDetail;
