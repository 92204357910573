export const MAX_LENGTH = 4000;

export const MAX_LENGTH_ROW = 4;

export const DEFAULT_PROMPTS = [
  'Write a SEO-enhanced property description: [insert description here] in 100 words or less.',
  'Develop a 5 step plan to promote [builder name] on social media.',
  'Write me a Tik Tok Script for selling new for my builder [builder name]',
  'Design and create a new email marketing campaign for this community: [community name] and description: [paragrah description].',
];
