import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'icons';
import PAGES from 'routes/constants';
import './index.scss';

const CommunityFooter = () => {
  return (
    <footer className="community-footer">
      <div className="footer-wrapper">
        <nav>
          <div className="nav-item">
            <Link to={PAGES.home}>Home</Link>
          </div>
          <div className="nav-item">
            <Link to={PAGES.home}>Blog</Link>
          </div>
          <div className="nav-item">
            <Link to={PAGES.home}>Help center</Link>
          </div>
          <div className="nav-item">
            <Link to={PAGES.home}>Privacy</Link>
          </div>
          <div className="nav-item">
            <Link to={PAGES.home}>Terms</Link>
          </div>
          <div className="nav-item">
            <Link to={PAGES.home}>Affiliate Partners</Link>
          </div>
          <div className="nav-item">
            <Link to={PAGES.home}>Status</Link>
          </div>
        </nav>

        <div className="social-wrapper">
          <a href="https://www.facebook.com/groups/homescribe">
            <Icon name="fb" />
          </a>
          <a href="https://www.instagram.com/homescribe/">
            <Icon name="instagram" />
          </a>
          <a href="https://twitter.com/homescribe">
            <Icon name="twitter" />
          </a>
          <a href="https://www.linkedin.com/company/homescribe">
            <Icon name="linkedin" />
          </a>
        </div>

        <p className="copyright">© 2023 HomeScribe. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default CommunityFooter;
