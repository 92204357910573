export enum ChatRole {
  user = 'user',
  system = 'system',
  assistant = 'assistant',
}

export interface ChatContent {
  role: ChatRole;
  name?: string;
  content: string;
}
