import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Menu, Input, Button, Table } from 'antd';
import type { MenuProps } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';

import Icon from 'icons';
import PAGES, { getDocumentDetailURL } from 'routes/constants';
import API from 'services/api';
import QUERY_KEYS from 'services/api/queryKeys';
import type { Document } from 'services/api/type/document.type';
import { useUser } from 'stores/auth/auth.selector';

import './index.scss';

const items: MenuProps['items'] = [
  {
    label: <Link to={PAGES.documents}>Documents</Link>,
    key: PAGES.documents,
  },
  {
    label: <Link to={PAGES.documentTrash}>Trash</Link>,
    key: PAGES.documentTrash,
  },
];

const PAGE_SIZE = 5;

const Documents = () => {
  const navigate = useNavigate();
  const me = useUser();
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const [selectedRows, setSelectedRows] = useState<Document[]>([]);
  const { data: documents, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_DOCUMENTS],
    queryFn: API.document.getDocuments,
  });

  const columns: ColumnsType<Document> = [
    {
      key: 'name',
      title: 'NAME',
      dataIndex: 'name',
      ellipsis: true,
      width: '36rem',
    },
    {
      key: 'ownerId',
      title: 'CREATED BY',
      dataIndex: 'ownerId',
      render: (value) => {
        return value === me.id ? 'me' : value;
      },
    },
    {
      key: 'updatedAt',
      title: 'MODIFIED',
      dataIndex: 'updatedAt',
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Document[]) => {
      setSelectedRows(selectedRows);
    },
  };

  const handleChangePage = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div className="documents-page">
      <div className="documents-top">
        <div className="documents-top__left">
          <h2>Documents</h2>
          <div className="documents-top__menu">
            <Menu
              defaultSelectedKeys={[PAGES.documents]}
              mode="horizontal"
              items={items}
            />
          </div>

          <div className="documents-top__selected-items">
            {selectedRows.length > 0 && (
              <div className="selected-number">
                <span>{selectedRows.length} Selected</span>
              </div>
            )}

            {selectedRows.length === 1 && (
              <Button className="btn-action">
                <Icon name="edit" />
              </Button>
            )}

            {selectedRows.length > 0 && (
              <>
                <Button className="btn-action">
                  <Icon name="duplicate" />
                </Button>
                <Button className="btn-action">
                  <Icon name="move" />
                </Button>
                <Button className="btn-action">
                  <Icon name="trash" />
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="documents-top__right">
          <div className="documents-top__right__search">
            <Input
              className="input-outline"
              placeholder="Search documents"
              prefix={<Icon name="search" />}
              suffix={<kbd>/</kbd>}
            />
          </div>

          <Link to={PAGES.addDocument}>
            <Button className="btn-gradient">New document</Button>
          </Link>
        </div>
      </div>

      <div className="documents-body">
        <Table
          rowKey={(value: Document) => value.id}
          size="small"
          loading={isLoading}
          rowSelection={{
            ...rowSelection,
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(getDocumentDetailURL(record.id));
              }, // click row
            };
          }}
          columns={columns}
          dataSource={documents}
          pagination={{
            defaultPageSize: PAGE_SIZE,
            showTotal: (total) => {
              const currentNumbers = (currentPage || 0) * PAGE_SIZE;
              return (
                <div>
                  {currentPage} —{' '}
                  {currentNumbers <= total ? currentNumbers : total} of {total}
                </div>
              );
            },
          }}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
};

export default Documents;
