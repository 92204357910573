import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonOutput = (props: any) => (
  <ContentLoader
    speed={2}
    width={'100%'}
    height={720}
    viewBox="0 0 100% 720"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="2%" y="10" rx="2" ry="2" width="96%" height="350" />
    <rect x="2%" y="380" rx="2" ry="2" width="96%" height="350" />
  </ContentLoader>
);

export default SkeletonOutput;
