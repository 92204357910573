import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import PAGES from 'routes/constants';
import './index.scss';
import Icon from 'icons';
import TemplateIcon from 'icons/template';
import type { IconType } from 'icons/template';
import { Template } from 'services/api/type/template.type';

type Props = {
  templateItem: Template;
};

const TemplateItem = ({ templateItem }: Props) => {
  return (
    <Link to={`${PAGES.templates}/${templateItem.id}`}>
      <div className="template-item">
        <div className="btn-favorite">
          <Button>
            <Icon name="star" />
          </Button>
        </div>

        <div className="template-info">
          <div className="template-logo">
            <TemplateIcon name={templateItem.icon as IconType} />
          </div>

          <h3>{templateItem.name}</h3>
          <p>{templateItem.description}</p>
        </div>
      </div>
    </Link>
  );
};

export default TemplateItem;
