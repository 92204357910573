import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { UserProfile } from './auth.reducer';

const storeName = 'authReducer';

export const selectUser = (state: any): UserProfile =>
  get(state, `${storeName}.user`, {});

export const useUser = () => {
  const user = useSelector(selectUser);
  return user;
};
