import createStorage from './createStorage';
import { AUTH_KEY, PREFIX } from 'constants/auth';
import { AuthToken } from 'models/user';

const instance = createStorage(PREFIX);
const KEY_PROFILE = 'profile';
const KEY_REFRESH_TOKEN = 'refreshToken';

export const setProfile = (data: any) => instance.setValue(KEY_PROFILE, data);

export const getValueUser = (key: string) => instance.getValue(key);

export const getProfile = () => instance.getValue(KEY_PROFILE);

export const setToken = (data: AuthToken) => instance.setValue(AUTH_KEY, data);

export const getToken = (): AuthToken => instance.getValue(AUTH_KEY);

export const clearToken = () => instance.clear();

export const setRefreshToken = (data: any) =>
  instance.setValue(KEY_REFRESH_TOKEN, data);
export const getRefreshToken = () => instance.getValue(KEY_REFRESH_TOKEN);
