import React from 'react';
import PAGES from './constants';
import HomePage from 'pages/home';
import LoginPage from 'pages/login';
import SignUpPage from 'pages/signup';
import TemplatesPage from 'pages/templates';
import TemplateDetailPage from 'pages/template-detail';
import DocumentsPage from 'pages/documents';
import DocumentDetailPage from 'pages/document-detail';
import ChatPage from 'pages/chat';
import RecipesPage from 'pages/recipes';
import RecipeEditPage from 'pages/recipes-edit';
import RecipeDetailPage from 'pages/recipes-detail';
import RecipesNewPage from 'pages/recipes-new';
import OnboardingPage from 'pages/onboarding';
import SettingProfilePage from 'pages/settting-profile';
import PureLayout from 'layouts/pure';
import RecipesCommunity from 'pages/recipes-community';

type RouteItem = {
  path: string;
  isPrivate: boolean;
  elem: React.FC;
  layout?: React.FC;
};

// TODO: remove later
const Dummy = () => null;

export const ROUTES: RouteItem[] = [
  { path: PAGES.home, elem: HomePage, isPrivate: true },
  { path: PAGES.templates, elem: TemplatesPage, isPrivate: true },
  { path: PAGES.templateDetail, elem: TemplateDetailPage, isPrivate: true },
  { path: PAGES.documents, elem: DocumentsPage, isPrivate: true },
  { path: PAGES.addDocument, elem: Dummy, isPrivate: true },
  { path: PAGES.documentTrash, elem: Dummy, isPrivate: true },
  { path: PAGES.documentDetail, elem: DocumentDetailPage, isPrivate: true },
  { path: PAGES.projects, elem: Dummy, isPrivate: true },
  { path: PAGES.help, elem: Dummy, isPrivate: true },
  { path: PAGES.settings, elem: Dummy, isPrivate: true },
  { path: PAGES.profileSetting, elem: SettingProfilePage, isPrivate: true },

  { path: PAGES.chat, elem: ChatPage, isPrivate: true },
  { path: PAGES.recipes, elem: RecipesPage, isPrivate: true },
  { path: PAGES.recipeEdit, elem: RecipeEditPage, isPrivate: true },
  { path: PAGES.recipeNew, elem: RecipesNewPage, isPrivate: true },
  { path: PAGES.recipeDetail, elem: RecipeDetailPage, isPrivate: true },
  { path: PAGES.communityRecipes, elem: RecipesCommunity, isPrivate: true },

  { path: PAGES.login, elem: LoginPage, isPrivate: false },
  { path: PAGES.signup, elem: SignUpPage, isPrivate: false },
  {
    path: PAGES.onboarding,
    elem: OnboardingPage,
    isPrivate: true,
    layout: PureLayout,
  },
];
