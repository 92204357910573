import baseApi from '../base.api';
import { paths } from '../paths';

export const getTemplates = () => {
  return baseApi.get(paths.getTemplates());
};

export const getTemplateDetail = (templateId: string) => {
  return baseApi.get(paths.getTemplateDetail(templateId));
};

export const createCompletion = (
  templateId: string,
  data: any,
  count: number,
) => {
  return baseApi.post(paths.createCompletion(templateId), {
    data,
    count,
  });
};
