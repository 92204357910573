import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import Icon, { ICON } from 'icons';
import './index.scss';

import PAGES from 'routes/constants';
import API from 'services/api';
import VerifyCode from 'components/auth/verify-code';

import { EmailRegex } from 'constants/regex';
import { supabase } from 'services/supabase/supabase';
import { RegisterType } from 'services/api/type/user.type';

const Signup = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [success, setSuccess] = useState(false);
  const email = Form.useWatch('email', form);
  const firstName = Form.useWatch('firstName', form);
  const lastName = Form.useWatch('lastName', form);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: RegisterType) => {
      return API.user.register(data);
    },
  });

  const onFinish = async (values: any) => {
    const { firstName, lastName, email } = values;

    try {
      const result = await mutateAsync({
        firstName,
        lastName,
        email,
      });
      if (result?.otpType === 'signup') {
        messageApi.open({
          type: 'success',
          content: 'Register successfully',
        });
        setSuccess(true);
        // form.resetFields();
      }
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error?.message,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const loginAsGoogle = async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: window.location.href,
      },
    });
  };

  /** TODO: ADD LOGOS for signups */

  const Logos = [
    // ICON.AIRBNB,
    // ICON.GOOGLE_LOGO,
    ICON.HOMESCRIBE_LOGO,
    ICON.ZILLOW,
    // ICON.IBM,
    // ICON.SCOTT,
    // ICON.ATT,
    // ICON.DEPOT,
    // ICON.VERIZON,
  ];

  return (
    <div className={`signup-page ${success ? 'center' : ''}`}>
      <div className="form-wrapper">
        <div className="form">
          <div className="form__logo-wrapper">
            <Icon name="logo" />
          </div>

          {success && (
            <VerifyCode
              email={email}
              title="You're almost signed up"
              actionText="to finish signing up."
              type="signup"
              firstName={firstName}
              lastName={lastName}
            />
          )}

          <div style={{ display: !success ? 'block' : 'none' }}>
            <h2 className="title">Create your account</h2>
            <p className="subtitle">You&apos;ll be up & running in 2 minutes</p>

            <div className="form__third-party-signup">
              <Button
                onClick={loginAsGoogle}
                className="btn-primary large w-full"
                type="primary"
              >
                <Icon name="google" />
                <span>Continue with Google</span>
              </Button>
            </div>

            <div className="form__divider">
              <div className="form__divider__line-wrapper">
                <div className="form__divider__line"></div>
              </div>
              <div className="form__divider__content">
                <span>Or register with your email</span>
              </div>
            </div>

            <div className="form__signup">
              <Form
                form={form}
                name="signup"
                layout="vertical"
                className="custom-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <div className="form__group">
                  <Form.Item
                    label="First name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your first name!',
                      },
                    ]}
                  >
                    <Input className="input-outline" />
                  </Form.Item>

                  <Form.Item
                    label="Last name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your last name!',
                      },
                    ]}
                  >
                    <Input className="input-outline" />
                  </Form.Item>
                </div>

                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    {
                      pattern: EmailRegex,
                      message: 'Please input a valid email!',
                    },
                  ]}
                >
                  <Input className="input-outline" />
                </Form.Item>

                <div className="form__signup__action">
                  <Button
                    className="btn-outline w-full"
                    type="default"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    <span>Sign up</span>
                    <Icon name="arrow-right" />
                  </Button>
                </div>
              </Form>
            </div>

            <div className="form__extra">
              <p>
                Already have an account? <Link to={PAGES.login}>Login</Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {!success && (
        <div className="signup-background">
          <div className="introduction">
            <p className="introduction-title">
              Join over 100+ new home sales and marketing leaders using AI to
              10x their productivity!
            </p>
            <div className="agencies">
              {Logos.map((item, index) => (
                <div className="agency-item" key={index}>
                  <Icon name={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {contextHolder}
    </div>
  );
};

export default Signup;
