import React from 'react';

import { ReactComponent as ArrowRight } from 'assets/images/arrow-right.svg';
import { ReactComponent as Billing } from 'assets/images/billing.svg';
import { ReactComponent as Burger } from 'assets/images/burger.svg';
import { ReactComponent as CaretUpDown } from 'assets/images/caret-up-down.svg';
import { ReactComponent as ChevDown } from 'assets/images/chev-down.svg';
import { ReactComponent as ChevRight } from 'assets/images/chev-right.svg';
import { ReactComponent as Close } from 'assets/images/close.svg';
import { ReactComponent as Credit } from 'assets/images/credit.svg';
import { ReactComponent as Document } from 'assets/images/document.svg';
import { ReactComponent as Google } from 'assets/images/google.svg';
import { ReactComponent as Help } from 'assets/images/help.svg';
import { ReactComponent as Home } from 'assets/images/home.svg';
import { ReactComponent as Light } from 'assets/images/light.svg';
import { ReactComponent as Logo } from 'assets/images/logo/logo.svg';
import { ReactComponent as LogoV2 } from 'assets/images/logo/logo-v2.svg';
import { ReactComponent as Plus } from 'assets/images/plus.svg';
import { ReactComponent as Recipe } from 'assets/images/recipe.svg';
import { ReactComponent as Search } from 'assets/images/search.svg';
import { ReactComponent as Setting } from 'assets/images/setting.svg';
import { ReactComponent as Signout } from 'assets/images/signout.svg';
import { ReactComponent as Switch } from 'assets/images/switch.svg';
import { ReactComponent as Template } from 'assets/images/template.svg';
import { ReactComponent as Time } from 'assets/images/time.svg';
import { ReactComponent as Star } from 'assets/images/star.svg';
import { ReactComponent as Info } from 'assets/images/info.svg';
import { ReactComponent as Play } from 'assets/images/play.svg';
import { ReactComponent as Lightning } from 'assets/images/lightning.svg';
import { ReactComponent as Copy } from 'assets/images/copy.svg';
import { ReactComponent as DocumentV2 } from 'assets/images/document-v2.svg';
import { ReactComponent as Thumb } from 'assets/images/thumb.svg';
import { ReactComponent as DownThumb } from 'assets/images/down-thumb.svg';
import { ReactComponent as Duplicate } from 'assets/images/duplicate.svg';
import { ReactComponent as Trash } from 'assets/images/trash.svg';
import { ReactComponent as Move } from 'assets/images/move.svg';
import { ReactComponent as Edit } from 'assets/images/edit.svg';
import { ReactComponent as ArrowLeft } from 'assets/images/arrow-left.svg';
import { ReactComponent as Reset } from 'assets/images/reset.svg';
import { ReactComponent as View } from 'assets/images/view.svg';
import { ReactComponent as Hide } from 'assets/images/hide.svg';
import { ReactComponent as Send } from 'assets/images/send.svg';
import { ReactComponent as Lock } from 'assets/images/lock.svg';
import { ReactComponent as Link } from 'assets/images/link.svg';
import { ReactComponent as Chat } from 'assets/images/chat.svg';
import { ReactComponent as Group } from 'assets/images/group.svg';
import { ReactComponent as ChevRightV2 } from 'assets/images/chev-right-v2.svg';
import { ReactComponent as Save } from 'assets/images/save.svg';
import { ReactComponent as Facebook } from 'assets/images/logo/fb.svg';
import { ReactComponent as Instagram } from 'assets/images/logo/instagram.svg';
import { ReactComponent as Twitter } from 'assets/images/logo/twitter.svg';
import { ReactComponent as LinkedIn } from 'assets/images/logo/linkedin.svg';
import { ReactComponent as Workflow } from 'assets/images/workflow.svg';
import { ReactComponent as Check } from 'assets/images/check.svg';

import AirBnb from 'assets/images/logo/airbnb.svg';
import ATT from 'assets/images/logo/at&t.svg';
import Depot from 'assets/images/logo/depot.svg';
import GoogleLogo from 'assets/images/logo/google-logo.svg';
import Ibm from 'assets/images/logo/ibm.svg';
import Scott from 'assets/images/logo/scott.svg';
import Verizon from 'assets/images/logo/verizon.svg';
import Zillow from 'assets/images/logo/zillow.svg';
import DefaultAvatar from 'assets/images/avatar-person.svg';
import HomeScribeLogo from 'assets/images/logo/home-scribe-logo-with-text.svg';

export type LogoType =
  | 'airbnb'
  | 'at&t'
  | 'depot'
  | 'google-logo'
  | 'ibm'
  | 'logo'
  | 'logoV2'
  | 'scott'
  | 'verizon'
  | 'zillow'
  | 'homescribe-logo'
  | 'fb'
  | 'instagram'
  | 'twitter'
  | 'linkedin';

export type IconType =
  | 'google'
  | 'arrow-right'
  | 'caret-up-down'
  | 'chev-right'
  | 'close'
  | 'billing'
  | 'credit'
  | 'document'
  | 'help'
  | 'home'
  | 'plus'
  | 'recipe'
  | 'setting'
  | 'signout'
  | 'switch'
  | 'template'
  | 'time'
  | 'default-avatar'
  | 'chev-down'
  | 'burger'
  | 'light'
  | 'search'
  | 'star'
  | 'info'
  | 'play'
  | 'lightning'
  | 'copy'
  | 'document-v2'
  | 'thumb'
  | 'down-thumb'
  | 'duplicate'
  | 'trash'
  | 'move'
  | 'edit'
  | 'arrow-left'
  | 'reset'
  | 'view'
  | 'hide'
  | 'send'
  | 'lock'
  | 'link'
  | 'chat'
  | 'group'
  | 'chev-right-v2'
  | 'save'
  | 'workflow'
  | 'check';

export enum ICON {
  AIRBNB = 'airbnb',
  ARROW_RIGHT = 'arrow-right',
  ATT = 'at&t',
  BILLING = 'billing',
  CARET_UP_DOWN = 'caret-up-down',
  CHEV_DOWN = 'chev-down',
  CHEV_RIGHT = 'chev-right',
  CLOSE = 'close',
  CREDIT = 'credit',
  DEFAULT_AVATAR = 'default-avatar',
  DEPOT = 'depot',
  DOCUMENT = 'document',
  GOOGLE_ICON = 'google',
  GOOGLE_LOGO = 'google-logo',
  HELP = 'help',
  HOME = 'home',
  IBM = 'ibm',
  LOGO = 'logo',
  LOGO_V2 = 'logoV2',
  PLUS = 'plus',
  RECIPE = 'recipe',
  SCOTT = 'scott',
  SETTING = 'setting',
  SIGN_OUT = 'signout',
  SWITCH = 'switch',
  TEMPLATE = 'template',
  TIME = 'time',
  VERIZON = 'verizon',
  ZILLOW = 'zillow',
  LIGHT = 'light',
  BURGER = 'burger',
  SEARCH = 'search',
  STAR = 'star',
  INFO = 'info',
  PLAY = 'play',
  LIGHTNING = 'lightning',
  DOCUMENT_V2 = 'document-v2',
  THUMB = 'thumb',
  DOWN_THUMB = 'down-thumb',
  COPY = 'copy',
  DUPLICATE = 'duplicate',
  TRASH = 'trash',
  MOVE = 'move',
  EDIT = 'edit',
  ARROW_LEFT = 'arrow-left',
  RESET = 'reset',
  VIEW = 'view',
  HIDE = 'hide',
  HOMESCRIBE_LOGO = 'homescribe-logo',
  SEND = 'send',
  LOCK = 'lock',
  LINK = 'link',
  CHAT = 'chat',
  GROUP = 'group',
  CHEV_RIGHT_v2 = 'chev-right-v2',
  SAVE = 'save',
  FACEBOOK = 'fb',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  WORKFLOW = 'workflow',
  CHECK = 'check',
}

interface Props {
  name: IconType | LogoType;
}

const Icons = {
  [ICON.AIRBNB]: <img src={AirBnb} alt="airbnb" />,
  [ICON.ATT]: <img src={ATT} alt="att" />,
  [ICON.DEPOT]: <img src={Depot} alt="depot" />,
  [ICON.GOOGLE_LOGO]: <img src={GoogleLogo} alt="google" />,
  [ICON.IBM]: <img src={Ibm} alt="ibm" />,
  [ICON.SCOTT]: <img src={Scott} alt="scott" />,
  [ICON.VERIZON]: <img src={Verizon} alt="verizon" />,
  [ICON.ZILLOW]: <img src={Zillow} alt="zillow" />,
  [ICON.HOMESCRIBE_LOGO]: <img src={HomeScribeLogo} alt="Homescribe" />,
  [ICON.LOGO]: <Logo />,
  [ICON.LOGO_V2]: <LogoV2 />,
  [ICON.GOOGLE_ICON]: <Google />,
  [ICON.ARROW_RIGHT]: <ArrowRight />,
  [ICON.CARET_UP_DOWN]: <CaretUpDown />,
  [ICON.CHEV_RIGHT]: <ChevRight />,
  [ICON.CLOSE]: <Close />,
  [ICON.BILLING]: <Billing />,
  [ICON.CREDIT]: <Credit />,
  [ICON.DOCUMENT]: <Document />,
  [ICON.HELP]: <Help />,
  [ICON.HOME]: <Home />,
  [ICON.PLUS]: <Plus />,
  [ICON.RECIPE]: <Recipe />,
  [ICON.SETTING]: <Setting />,
  [ICON.SIGN_OUT]: <Signout />,
  [ICON.SWITCH]: <Switch />,
  [ICON.TEMPLATE]: <Template />,
  [ICON.TIME]: <Time />,
  [ICON.CHEV_DOWN]: <ChevDown />,
  [ICON.BURGER]: <Burger />,
  [ICON.LIGHT]: <Light />,
  [ICON.SEARCH]: <Search />,
  [ICON.STAR]: <Star />,
  [ICON.INFO]: <Info />,
  [ICON.PLAY]: <Play />,
  [ICON.LIGHTNING]: <Lightning />,
  [ICON.DOCUMENT_V2]: <DocumentV2 />,
  [ICON.THUMB]: <Thumb />,
  [ICON.DOWN_THUMB]: <DownThumb />,
  [ICON.COPY]: <Copy />,
  [ICON.EDIT]: <Edit />,
  [ICON.TRASH]: <Trash />,
  [ICON.MOVE]: <Move />,
  [ICON.DUPLICATE]: <Duplicate />,
  [ICON.ARROW_LEFT]: <ArrowLeft />,
  [ICON.RESET]: <Reset />,
  [ICON.HIDE]: <Hide />,
  [ICON.VIEW]: <View />,
  [ICON.SEND]: <Send />,
  [ICON.LINK]: <Link />,
  [ICON.LOCK]: <Lock />,
  [ICON.CHAT]: <Chat />,
  [ICON.GROUP]: <Group />,
  [ICON.CHEV_RIGHT_v2]: <ChevRightV2 />,
  [ICON.SAVE]: <Save />,
  [ICON.FACEBOOK]: <Facebook />,
  [ICON.INSTAGRAM]: <Instagram />,
  [ICON.TWITTER]: <Twitter />,
  [ICON.LINKEDIN]: <LinkedIn />,
  [ICON.WORKFLOW]: <Workflow />,
  [ICON.CHECK]: <Check />,
  [ICON.DEFAULT_AVATAR]: <img src={DefaultAvatar} alt="avatar" />,
};

function Icon({ name }: Props) {
  return Icons[name] || null;
}

export default React.memo(Icon);
