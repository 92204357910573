import React from 'react';
import { Outlet } from 'react-router-dom';

import CommunityHeader from 'components/layouts/community/header';
import CommunityFooter from 'components/layouts/community/footer';
import './index.scss';

const CommunityLayout = () => {
  return (
    <div className="community-layout">
      <CommunityHeader />

      <div className="community-content">
        <Outlet />
      </div>

      <CommunityFooter />
    </div>
  );
};

export default CommunityLayout;
