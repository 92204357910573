import io, { Socket as SocketIo } from 'socket.io-client';
import { getToken } from 'services/storages/userStorage';

export class Socket {
  private socket: SocketIo;
  public static instance: Socket;
  constructor() {
    const baseURL = process.env.REACT_APP_API_URL;
    const authToken = getToken();
    this.socket = io(baseURL as string, {
      transports: ['websocket'],
      autoConnect: false,
      reconnection: true,
      auth: {
        token: authToken.access_token,
      },
    });
  }

  static getInstance() {
    if (Socket.instance) {
      return Socket.instance;
    }
    Socket.instance = new Socket();
    return Socket.instance;
  }

  open() {
    this.socket.open();
  }

  close() {
    this.socket.close();
    this.socket.disconnect();
  }

  sendEvent(eventName: string, payload: any) {
    this.socket.emit(eventName, payload);
  }

  on(eventName: string, callback: any) {
    const func = (data: any) => {
      callback(data);
    };
    this.socket.on(eventName, func);

    return () => this.socket.off(eventName, func);
  }
}

export default Socket;
