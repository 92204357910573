import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PAGES from './constants';
import { getToken } from 'services/storages/userStorage';
import PrepareLayout from '../layouts/prepare.layout';

interface Props {
  children: ReactNode;
}

const PrivateRoutes = ({ children }: Props) => {
  const token = getToken();
  if (!token) {
    return <Navigate to={PAGES.login} />;
  }
  return <PrepareLayout>{children}</PrepareLayout>;
};

export default PrivateRoutes;
