import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, message } from 'antd';
import { useDispatch } from 'react-redux';

import API from 'services/api';
import Icon from 'icons';
import VerifyCode from 'components/auth/verify-code';
import './index.scss';

import { saveToken } from 'stores/auth/auth.reducer';
import PAGES from 'routes/constants';
import { EmailRegex } from 'constants/regex';
import { LoginType } from 'services/api/type/user.type';
import { supabase } from 'services/supabase/supabase';
import { getToken } from 'services/storages/userStorage';

const Login = () => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const email = Form.useWatch('email', form);
  const [success, setSuccess] = useState(false);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: LoginType) => {
      return API.user.login(data);
    },
  });

  useEffect(() => {
    supabase.auth.onAuthStateChange((event) => {
      if (event === 'SIGNED_IN') {
        dispatch(saveToken(getToken()));
        window.open(PAGES.home, '_self');
      }
    });
  }, []);

  const loginAsGoogle = async () => {
    await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: window.location.href,
      },
    });
  };

  const onFinish = async (values: any) => {
    try {
      const result = await mutateAsync(values);
      if (result?.otpType === 'magiclink') {
        setSuccess(true);
      }
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error?.message,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="login-page">
      <div className="form-wrapper">
        <div className="form">
          <div className="form__logo-wrapper">
            <Icon name="logo" />
          </div>

          <div style={{ display: !success ? 'block' : 'none' }}>
            <p className="title">Sign in to your account</p>

            <div className="form__third-party-login">
              <Button
                onClick={loginAsGoogle}
                className="btn-primary large w-full"
                type="primary"
              >
                <Icon name="google" />
                <span>Continue with Google</span>
              </Button>
            </div>

            <div className="form__divider">
              <div className="form__divider__line-wrapper">
                <div className="form__divider__line"></div>
              </div>
              <div className="form__divider__content">
                <span>Or sign in with your email</span>
              </div>
            </div>

            <div className="form__login">
              <Form
                form={form}
                name="login"
                layout="vertical"
                className="custom-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your email!' },
                    {
                      pattern: EmailRegex,
                      message: 'Please input a valid email!',
                    },
                  ]}
                >
                  <Input className="input-outline" />
                </Form.Item>

                <div className="form__login__action">
                  <Button
                    className="btn-outline w-full"
                    type="default"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    <span>Continue with Email</span>
                  </Button>
                </div>
              </Form>
            </div>

            <div className="form__extra">
              <p>
                Don&apos;t have an account yet?{' '}
                <Link to={PAGES.signup}>Get started here</Link>
              </p>
            </div>
          </div>

          {success && (
            <VerifyCode
              title="Login to HomeScribe.AI"
              actionText="to sign in"
              email={email}
              type="magiclink"
            />
          )}
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

export default Login;
