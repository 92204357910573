import React from 'react';
import OutputItem from 'components/template-detail/output-item';

type Props = {
  outputs: any;
};

const NewOutputTab = ({ outputs }: Props) => {
  return outputs.map((item: any) => (
    <OutputItem outputItem={item} key={item.index} />
  ));
};

export default NewOutputTab;
