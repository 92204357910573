import baseApi from '../base.api';
import {
  LoginType,
  RegisterType,
  VerifyEmailType,
  ForgotPasswordType,
  ResetPasswordType,
  ChangePasswordType,
  RequestChangeEmailType,
  SubmitOnboarding,
  UpdateProfile,
} from '../type/user.type';
import { paths } from '../paths';

export const login = (data: LoginType) => {
  return baseApi.post(paths.login(), data);
};

export const register = (data: RegisterType) => {
  return baseApi.post(paths.register(), data);
};

export const verifyEmail = (data: VerifyEmailType) => {
  return baseApi.post(paths.verifyEmail(), data);
};

export const verifyNewEmail = (data: VerifyEmailType) => {
  return baseApi.post(paths.verifyNewEmail(), data);
};

export const forgotPassword = (data: ForgotPasswordType) => {
  return baseApi.get(paths.forgotPassword(), data);
};

export const resetPassword = (data: ResetPasswordType) => {
  return baseApi.post(paths.resetPassword(), data);
};

export const changePassword = (data: ChangePasswordType) => {
  return baseApi.post(paths.changePassword(), data);
};

export const requestChangeEmail = (data: RequestChangeEmailType) => {
  return baseApi.post(paths.requestChangeEmail(), data);
};

export const updateProfile = (data: UpdateProfile) => {
  return baseApi.put(paths.updateProfile(), data);
};

export const getProfile = () => {
  return baseApi.get(paths.me());
};

export const submitOnboarding = (data: SubmitOnboarding) => {
  return baseApi.post(paths.submitOnboarding(), data);
};
