export const paths = {
  // auth
  login() {
    return `/auth/login`;
  },
  register() {
    return `/auth/register`;
  },
  verifyEmail() {
    return `/auth/verify_otp`;
  },
  forgotPassword() {
    return `/auth/forgot-password`;
  },
  resetPassword() {
    return `/auth/reset-password`;
  },
  me() {
    return `/auth/me`;
  },
  submitOnboarding() {
    return `/auth/submit-onboarding`;
  },
  // user
  changePassword() {
    return `/api/v1/users/change-password`;
  },
  requestChangeEmail() {
    return `/api/v1/users/request-change-email`;
  },
  updateProfile() {
    return `/auth/profile`;
  },
  verifyNewEmail() {
    return `/api/v1/users/confirm-change-email`;
  },
  getProfile() {
    return `/api/v1/users/profile`;
  },

  // templates
  getTemplates() {
    return `/templates`;
  },
  getTemplateDetail(id: string) {
    return `/templates/${id}`;
  },
  createCompletion(id: string) {
    return `/templates/${id}/completion`;
  },

  // Documents
  getDocuments() {
    return `/documents`;
  },
  getDocumentDetail(id: string) {
    return `/documents/${id}`;
  },
  // AI
  aiCreateCompletion() {
    return `/ai/completion`;
  },

  // Recipes
  getRecipes() {
    return `/recipes`;
  },
  getFeatureRecipes() {
    return `/recipes/featured`;
  },
  getCommunityRecipes() {
    return `/recipes/community`;
  },
  getRecipeDetail(id: string) {
    return `/recipes/${id}`;
  },
  updateRecipe(id: string) {
    return `/recipes/${id}`;
  },
  deleteRecipe(id: string) {
    return `/recipes/${id}`;
  },

  // Prompt
  getPrompts() {
    return `/prompts`;
  },
};
