import baseApi from '../base.api';
import { paths } from '../paths';
import type { Recipe } from '../type/recipe.type';
import { UpdateRecipe } from '../type/recipe.type';

export const getRecipes = (): Promise<Recipe[]> => {
  return baseApi.get(paths.getRecipes());
};

export const getFeatureRecipes = (): Promise<Recipe[]> => {
  return baseApi.get(paths.getFeatureRecipes());
};

export const getCommunityRecipes = (): Promise<Recipe[]> => {
  return baseApi.get(paths.getCommunityRecipes());
};

export const getRecipeDetail = (recipeId: string): Promise<Recipe> => {
  return baseApi.get(paths.getRecipeDetail(recipeId));
};

export const updateRecipe = (
  recipeId: string,
  data: UpdateRecipe,
): Promise<Recipe> => {
  return baseApi.patch(paths.updateRecipe(recipeId), data);
};

export const createRecipe = (data: UpdateRecipe): Promise<Recipe> => {
  return baseApi.post(paths.getRecipes(), data);
};

export const deleteRecipe = (recipeId: string): Promise<Recipe> => {
  return baseApi.delete(paths.deleteRecipe(recipeId));
};
