import React, { useState } from 'react';
import ColorHash from 'color-hash';
import { Avatar, Button, Input, Table } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import moment from 'moment/moment';

import Icon, { ICON, IconType } from 'icons';
import PAGES, {
  getChatURL,
  getRecipeCommunityURL,
  getRecipeDetailURL,
} from 'routes/constants';
import API from 'services/api';
import QUERY_KEYS from 'services/api/queryKeys';
import { RECIPE_STATUS } from 'services/api/type/recipe.type';
import SeeMoreText from 'components/common/see-more/SeeMore';

import './index.scss';
import { getStandForCharacter } from 'utils';

const colorHash = new ColorHash();

const PAGE_SIZE = 5;
const RecipesPage = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const { data: recipes } = useQuery({
    queryKey: [QUERY_KEYS.GET_RECIPES],
    queryFn: API.recipe.getRecipes,
  });

  const { data: featuredRecipes } = useQuery({
    queryKey: [QUERY_KEYS.GET_FEATURE_RECIPES],
    queryFn: API.recipe.getFeatureRecipes,
  });

  const MapIcon = {
    [RECIPE_STATUS.PRIVATE]: 'lock',
    [RECIPE_STATUS.PUBLIC]: 'link',
  } as any;

  const handleRunRecipe = (data: string) => {
    navigate(getChatURL(data));
  };

  const columns: ColumnsType<any> = [
    {
      title: 'NAME',
      dataIndex: 'name',
      ellipsis: true,
      // width: '24rem',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (value) => {
        return <SeeMoreText text={value} maxLength={120} />;
      },
    },
    {
      title: 'LAST USED',
      dataIndex: 'updatedAt',
      render: (value) => {
        return moment(value).format('MM-DD-YYYY');
      },
    },
    {
      title: 'SHARING',
      dataIndex: 'status',
      render: (value) => {
        const isFade = value === RECIPE_STATUS.PRIVATE ? 'fade' : '';
        value = RECIPE_STATUS.PUBLIC; // TODO: Remove later
        return (
          <span className={`icon-status ${isFade}`}>
            <Icon name={MapIcon[value] as IconType} />
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      render: (item, data) => {
        return (
          <div className="d-flex-align-center">
            <Button
              onClick={() => handleRunRecipe(data.content)}
              className="btn-gradient"
            >
              <Icon name="play" />
              Run
            </Button>
            <Button
              onClick={() => navigate(getRecipeDetailURL(data.id))}
              className="btn-edit"
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];

  const handleChangePage = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div className="recipes-page">
      <div className="recipes-top">
        <div className="recipes-top__inner">
          <h1>Recipes</h1>
          <div className="recipes-top__inner__action">
            <div className="recipes-top__inner__action__search">
              <Input
                className="input-outline"
                placeholder="Search recipes"
                prefix={<Icon name="search" />}
                suffix={<kbd>/</kbd>}
              />

              <Button
                onClick={() => navigate(PAGES.recipeNew)}
                className="btn-gradient"
              >
                New recipe
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="recipes-content">
        <article>
          <div className="recipes-content__popular">
            <div className="recipes-content__popular__top">
              <h2>Start from a featured recipe</h2>
              <p>
                Copying an example recipe can help you build your own. Or check
                out{' '}
                <Link to={PAGES.communityRecipes}>
                  Recipes from the community.
                </Link>
              </p>
            </div>
            <div className="recipes-content__popular__list">
              {!!featuredRecipes?.length &&
                featuredRecipes.map((item) => {
                  return (
                    <div
                      className="recipes-content__popular-item"
                      key={item.id}
                    >
                      <Avatar
                        style={{
                          backgroundColor: colorHash.hex(
                            getStandForCharacter(item.owner.name),
                          ),
                          color: '#fff',
                        }}
                      >
                        {getStandForCharacter(item.owner.name)}
                      </Avatar>
                      <div className="featured-img">
                        <Icon name={ICON.HOMESCRIBE_LOGO} />
                      </div>
                      <h4>{item.name}</h4>
                      <p className="desc">{item.description}</p>
                      <div className="overlay">
                        <Link to={getRecipeCommunityURL(item.id)}>
                          <Button className="btn-recipe">Open recipe</Button>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <h2 className="title-your-recipes">Your recipes</h2>

          <div className="your-recipes">
            <Table
              rowKey={(record) => record.id}
              // loading={isLoading}
              // onRow={(record) => {
              //   return {
              //     onClick: () => {
              //       navigate(getRecipeDetailURL(record.id));
              //     }, // click row
              //   };
              // }}
              columns={columns}
              dataSource={recipes}
              pagination={{
                defaultPageSize: PAGE_SIZE,
                showTotal: (total) => {
                  const currentNumbers = (currentPage || 0) * PAGE_SIZE;
                  return (
                    <div>
                      {currentPage} —{' '}
                      {currentNumbers <= total ? currentNumbers : total} of{' '}
                      {total}
                    </div>
                  );
                },
              }}
              onChange={handleChangePage}
            />
          </div>
        </article>
      </div>
    </div>
  );
};

export default RecipesPage;
