import { cloneDeep, lowerCase } from 'lodash';
import { YoutubeURLRegex } from 'constants/regex';

export function splitString(str?: string): string[] {
  if (!str || str === '') {
    return [];
  }
  return str.split(',');
}

export function deepCopy<T>(json: T): T {
  return cloneDeep(json);
}

export function encodeQueryData(data: any) {
  const ret = [];
  for (const d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function formatCurrency(num: number): string {
  return String(num.toFixed(2)).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function formatTitle(title: string) {
  return capitalize(lowerCase(title));
}

export enum THUMBNAIL_YOUTUBE_QUALITY {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}
export function getYoutubeThumbnail(
  url: string,
  quality: 'low' | 'medium' | 'high',
) {
  if (url) {
    let video_id, thumbnail, result;
    if ((result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))) {
      video_id = result.pop();
    } else if ((result = url.match(/youtu.be\/(.{11})/))) {
      video_id = result.pop();
    }

    if (video_id) {
      if (typeof quality === 'undefined') {
        quality = 'high';
      }

      let quality_key = 'maxresdefault'; // Max quality
      if (quality === 'low') {
        quality_key = 'sddefault';
      } else if (quality === 'medium') {
        quality_key = 'mqdefault';
      } else if (quality === 'high') {
        quality_key = 'hqdefault';
      }

      thumbnail =
        'https://img.youtube.com/vi/' + video_id + '/' + quality_key + '.jpg';
      return thumbnail;
    }
  }
  return undefined;
}

export function convertYoutubeEmbed(url: string) {
  if (!YoutubeURLRegex.test(url)) {
    return '';
  }
  const v = new URL(url).searchParams.get('v');
  return `https://www.youtube.com/embed/${v}`;
}

export async function validYoutubeVideoId(url: string): Promise<boolean> {
  if (!YoutubeURLRegex.test(url)) {
    return false;
  }

  return new Promise((resolve) => {
    const id = new URL(url).searchParams.get('v');
    const img = new Image();
    img.src = 'http://img.youtube.com/vi/' + id + '/mqdefault.jpg';
    img.onload = function () {
      //HACK a mq thumbnail has width of 320.
      //if the video does not exist(therefore thumbnail don't exist), a default thumbnail of 120 width is returned.
      if (img.width === 120) {
        resolve(false);
      }
      resolve(true);
    };
  });
}

export function getStandForCharacter(name: string) {
  const arrWords = name.split(' ');
  const first = arrWords[0].at(0);
  const last = arrWords.at(-1)?.at(0) || '';
  return `${first}${last}`;
}

export function getShortenName(user: any): string {
  if (user?.profile?.fullName) {
    return `${String(user?.profile?.fullName).at(0)?.toUpperCase()}`;
  }
  return `${String(user?.firstName?.at(0)?.toUpperCase())}${String(
    user?.lastName?.at(0)?.toUpperCase(),
  )}`;
}

export async function clipboard(text: string) {
  return navigator.clipboard.writeText(text);
}
export function limitString(str: string, length = 150) {
  if (str?.length > length) {
    str = str.slice(0, length); // Keep the first 100 characters
    return `${str}...`;
  }
  return str;
}
