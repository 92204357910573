import React, { useEffect, useState } from 'react';
import { Button, Divider, Input, Radio, Col, Row } from 'antd';
import { useQuery } from '@tanstack/react-query';
import type { RadioChangeEvent } from 'antd';

import TemplateItem from 'components/templates/template-item';
import TemplateSkeleton from 'components/templates/template-item/skeleton';
import Icon from 'icons';
import { TAGS, TAG_VALUE } from 'constants/tag';
import QUERY_KEYS from 'services/api/queryKeys';
import API from 'services/api';
import { Template } from 'services/api/type/template.type';
import './index.scss';

const Templates = () => {
  const { data, isFetching } = useQuery<Template[]>({
    queryKey: [QUERY_KEYS.GET_TEMPLATES],
    queryFn: API.template.getTemplates,
  });
  const [filterData, setFilterData] = useState<Template[] | undefined>([]);

  useEffect(() => {
    setFilterData(data);
  }, [data]);

  const onChange = (e: RadioChangeEvent) => {
    const value = e.target.value;
    if (value === TAG_VALUE.ALL) {
      setFilterData(data);
    } else {
      const filtered = data?.filter((item) =>
        item.tags.toLowerCase().includes(value),
      );
      setFilterData(filtered);
    }
  };

  const renderTemplates = () => {
    if (isFetching) {
      return [...Array(10)].map((item, index) => {
        return (
          <Col
            className="gutter-row"
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={6}
            key={index}
          >
            <TemplateSkeleton />
          </Col>
        );
      });
    }

    if (!!filterData?.length) {
      return filterData.map((item) => (
        <Col
          className="gutter-row"
          xs={24}
          sm={12}
          md={12}
          lg={8}
          xl={6}
          key={item.id}
        >
          <TemplateItem templateItem={item} />
        </Col>
      ));
    }

    return null;
  };

  return (
    <div className="templates-page">
      <div className="templates-wrapper">
        <div className="templates-wrapper__top">
          <div className="templates-wrapper__top__title">
            <h2>Templates</h2>
          </div>

          <div className="templates-wrapper__top__action">
            <div className="d-flex-align-center">
              <div className="d-flex-align-center">
                <Button className="btn-action active">
                  <Icon name="light" />
                </Button>
                <Divider type="vertical" />
              </div>

              <div className="d-flex-center templates-wrapper__top__action__layout">
                <Button className="btn-action">
                  <Icon name="burger" />
                </Button>
                <Button className="btn-action">
                  <Icon name="template" />
                </Button>
              </div>
            </div>

            <div className="templates-wrapper__top__action__search">
              <Input
                className="input-outline"
                placeholder="Search..."
                prefix={<Icon name="search" />}
                suffix={<kbd>/</kbd>}
              />
            </div>
          </div>
        </div>

        <div className="templates-wrapper__tag">
          <Radio.Group onChange={onChange} defaultValue={TAGS[0].value}>
            {TAGS.map((item, index) => (
              <Radio.Button value={item.value} key={index}>
                {item.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>

        <div className="templates-wrapper__items">
          <Row gutter={[16, 16]}>{renderTemplates()}</Row>
        </div>
      </div>
    </div>
  );
};

export default Templates;
