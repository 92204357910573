import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Loading from 'components/common/loading';

import API from 'services/api';
import { getToken } from 'services/storages/userStorage';
import PAGES from '../routes/constants';
import { updateUser } from 'stores/auth/auth.reducer';

interface Props {
  children: any;
}

const PrepareLayout = ({ children }: Props) => {
  const token = getToken();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: API.user.getProfile,
  });

  useEffect(() => {
    const token = getToken();
    const exceptionRoutes = [] as any;
    if (token) {
      getProfile();
    } else if (!token && !exceptionRoutes.includes(location.pathname)) {
      // Check private routes
      navigate(PAGES.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfile = async () => {
    try {
      const result = await mutateAsync();
      if (!result.isOnboarding) {
        navigate(PAGES.onboarding);
      }
      dispatch(updateUser(result));
    } catch {
      navigate(PAGES.login);
    }
  };

  if (token && isLoading) {
    return (
      <div className="absolute-spinner">
        <Loading size={48} />
      </div>
    );
  }

  return children;
};

export default PrepareLayout;
