import baseApi from '../base.api';
import { paths } from '../paths';
import type { Document } from '../type/document.type';

export const getDocuments = (): Promise<Document[]> => {
  return baseApi.get(paths.getDocuments());
};

export const getDocumentDetail = (templateId: string) => {
  return baseApi.get(paths.getDocumentDetail(templateId));
};
