import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonBtnGenerate = (props: any) => (
  <ContentLoader
    speed={2}
    width={40}
    height={40}
    viewBox="0 0 40 40"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="40" height="40" />
  </ContentLoader>
);

export default SkeletonBtnGenerate;
