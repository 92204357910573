import React from 'react';
import './index.scss';
import { Button, Form, Input, Select } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { SubmitOnboarding } from 'services/api/type/user.type';
import API from 'services/api';
import PAGES from 'routes/constants';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'stores/auth/auth.selector';

const CATEGORY_VALUE = [
  {
    value: 'sales',
    label: 'Sales',
  },
  {
    value: 'marketing',
    label: 'Marketing',
  },
  {
    value: 'executive',
    label: 'Executive',
  },
  {
    value: 'operations',
    label: 'Operations',
  },
];
const Onboarding = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const user = useUser();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: SubmitOnboarding) => {
      return API.user.submitOnboarding(data);
    },
  });

  const onFinish = async (values: any) => {
    try {
      await mutateAsync({
        ...values,
        category: values.category.join(','),
      });
      navigate(PAGES.home);
    } catch (error: any) {}
  };

  return (
    <div className="onboarding-page">
      <div className="form-wrapper">
        <div className="form__onboarding">
          <p className="title">Welcome, {user?.firstName ?? user?.full_name}</p>
          <Form
            form={form}
            name="login"
            layout="vertical"
            className="custom-form"
            onFinish={onFinish}
          >
            <Form.Item
              label="Company name"
              name="companyName"
              rules={[
                { required: true, message: 'Please input your company name!' },
              ]}
            >
              <Input className="input-outline" />
            </Form.Item>

            <Form.Item
              label="Website"
              name="website"
              rules={[
                { required: true, message: 'Please input your website!' },
              ]}
            >
              <Input className="input-outline" />
            </Form.Item>
            <Form.Item
              label="Current job title"
              name="jobTitle"
              rules={[
                { required: true, message: 'Please input your job title!' },
              ]}
            >
              <Input className="input-outline" />
            </Form.Item>
            <Form.Item
              label="Category"
              name="category"
              rules={[
                { required: true, message: 'Please input your category!' },
              ]}
            >
              <Select
                className="input-outline"
                mode="multiple"
                options={CATEGORY_VALUE}
              />
            </Form.Item>

            <div className="form__login__action">
              <Button
                className="btn-outline w-full"
                type="default"
                htmlType="submit"
                loading={isLoading}
              >
                <span>Start using Homescribe.ai</span>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
