import React, { useEffect, useState } from 'react';
import { Col, Input, Row } from 'antd';
import { useQuery } from '@tanstack/react-query';
import TemplateSkeleton from 'components/templates/template-item/skeleton';
import Icon from 'icons';
import QUERY_KEYS from 'services/api/queryKeys';
import API from 'services/api';
import './index.scss';
import { Recipe } from 'services/api/type/recipe.type';
import RecipeItem from 'components/recipes/recipe-item';
import { debounce } from 'lodash';

const CommunityRecipes = () => {
  const { data, isFetching } = useQuery<Recipe[]>({
    queryKey: [QUERY_KEYS.GET_COMMUNITY_RECIPES],
    queryFn: API.recipe.getCommunityRecipes,
  });
  const [filterData, setFilterData] = useState<Recipe[] | undefined>([]);

  useEffect(() => {
    setFilterData(data);
  }, [data]);

  const handleInputChange = debounce((event) => {
    const inputValue = event.target.value;
    const newData = data?.filter(
      (item) =>
        item.name.toLowerCase().includes(inputValue?.toLowerCase()) ||
        item.description?.toLowerCase().includes(inputValue?.toLowerCase()),
    );
    setFilterData(newData);
  }, 500); // Debounce delay of 500 milliseconds

  const renderTemplates = () => {
    if (isFetching) {
      return [...Array(10)].map((item, index) => {
        return (
          <Col
            className="gutter-row"
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={6}
            key={index}
          >
            <TemplateSkeleton />
          </Col>
        );
      });
    }

    if (!!filterData?.length) {
      return filterData.map((item) => (
        <Col
          className="gutter-row"
          xs={24}
          sm={12}
          md={12}
          lg={8}
          xl={6}
          key={item.id}
        >
          <RecipeItem recipeItem={item} />
        </Col>
      ));
    }

    return null;
  };

  return (
    <div className="templates-page">
      <div className="templates-wrapper">
        <div className="templates-wrapper__top">
          <div className="templates-wrapper__top__title">
            <h2>Community recipes</h2>
          </div>

          <div className="templates-wrapper__top__action">
            <div className="templates-wrapper__top__action__search">
              <Input
                className="input-outline"
                placeholder="Search..."
                onChange={handleInputChange}
                prefix={<Icon name="search" />}
                suffix={<kbd>/</kbd>}
              />
            </div>
          </div>
        </div>

        <div className="templates-wrapper__items">
          <Row gutter={[16, 16]}>{renderTemplates()}</Row>
        </div>
      </div>
    </div>
  );
};

export default CommunityRecipes;
