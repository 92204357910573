import React, { useEffect } from 'react';
import './index.scss';
import { Button, Form, Input, Row, Col, Divider, Select, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { UpdateProfile } from 'services/api/type/user.type';
import API from 'services/api';
import { useUser } from 'stores/auth/auth.selector';
import { CATEGORY_OPTIONS } from 'constants/category';
import { updateUser } from 'stores/auth/auth.reducer';

const SettingProfile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const user = useUser();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: UpdateProfile) => {
      return API.user.updateProfile(data);
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      email: user?.email,
      firstName: user?.profile?.firstName,
      lastName: user?.profile?.lastName,
      companyName: user?.profile?.companyName,
      website: user?.profile?.website,
      jobTitle: user?.profile?.jobTitle,
      category: user?.profile?.category
        ? user?.profile?.category.split(',')
        : [],
    });
  }, []);

  const onFinish = async (values: any) => {
    console.log('values', values);
    delete values.email;

    try {
      const result = await mutateAsync({
        ...values,
        category: values.category.join(','),
      });

      const newUser = {
        ...user,
        profile: {
          ...user?.profile,
          ...result,
        },
      };
      console.log('aaaaaaa', newUser);
      dispatch(updateUser(newUser));

      messageApi.open({
        type: 'success',
        content: 'Update profile successfully',
      });
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error?.message,
      });
    }
  };

  return (
    <div className="setting-profile-page">
      <div className="form-wrapper">
        <div className="form__onboarding">
          <p className="title">Profile settings</p>
          <Form
            form={form}
            name="login"
            layout="vertical"
            className="custom-form"
            onFinish={onFinish}
          >
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item label="First name" name="firstName">
                  <Input className="input-outline" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Last name" name="lastName">
                  <Input className="input-outline" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Email" name="email">
              <Input className="input-outline" readOnly disabled />
            </Form.Item>

            <Form.Item label="Company name" name="companyName">
              <Input className="input-outline" />
            </Form.Item>

            <Form.Item label="Website" name="website">
              <Input className="input-outline" />
            </Form.Item>
            <Form.Item label="Current job title" name="jobTitle">
              <Input className="input-outline" />
            </Form.Item>
            <Form.Item label="Category" name="category">
              <Select
                className="input-outline"
                mode="multiple"
                options={CATEGORY_OPTIONS}
              />
            </Form.Item>

            <Divider />

            <div className="form__login__action">
              <Button
                className="btn-gradient"
                type="default"
                htmlType="submit"
                loading={isLoading}
              >
                <span>Save</span>
              </Button>
            </div>
          </Form>
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

export default SettingProfile;
