import React, { useEffect, useState } from 'react';
import { Button, Input, Form, Collapse, message, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Icon from 'icons';
import PAGES from 'routes/constants';
import { YoutubeURLRegex } from 'constants/regex';
import { validYoutubeVideoId, convertYoutubeEmbed } from 'utils';

import './index.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import API from 'services/api';
import { UpdateRecipe } from '~/services/api/type/recipe.type';

const { Panel } = Collapse;
const { confirm } = Modal;

const RecipesEditPage = () => {
  const [form] = Form.useForm();
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const { data: recipeDetail } = useQuery({
    queryKey: [QUERY_KEYS.GET_RECIPES, recipeId],
    queryFn: () => API.recipe.getRecipeDetail(String(recipeId)),
    onError: (error: any) => {
      message.error(error?.message || 'Template not found');
      navigate(PAGES.recipes);
    },
    retry: 0,
  });

  const { mutateAsync: updateRecipeAsync } = useMutation({
    mutationFn: (data: UpdateRecipe) => {
      return API.recipe.updateRecipe(recipeId as string, data);
    },
  });

  const { mutateAsync: deleteRecipeAsync } = useMutation({
    mutationFn: () => {
      return API.recipe.deleteRecipe(recipeId as string);
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      name: recipeDetail?.name || '',
      description: recipeDetail?.description || '',
      content: recipeDetail?.content || '',
      videoUrl: recipeDetail?.videoUrl || '',
    });
  }, [recipeDetail]);

  const showDeleteConfirm = () => {
    confirm({
      title: 'Do you want to delete this recipe?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      centered: true,
      onOk() {
        return new Promise(async (resolve) => {
          const result = await deleteRecipeAsync();
          resolve(result);
          navigate(PAGES.recipes);
        }).catch((error: any) => {
          throw error;
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onFinish = async (payload: any) => {
    try {
      await updateRecipeAsync(payload);
      messageApi.open({
        type: 'success',
        content: 'Update recipe successful',
      });
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error?.message,
      });
    }
  };

  const youtubeURL = Form.useWatch('videoUrl', form);
  const [isValidVideo, setIsValidVideo] = useState(false);

  const youtubeEmbedURL = React.useMemo(() => {
    if (!youtubeURL) {
      return '';
    }
    return convertYoutubeEmbed(youtubeURL);
  }, [youtubeURL]);

  const checkValidVideo = async () => {
    const isValidVideo = await validYoutubeVideoId(youtubeURL);
    setIsValidVideo(isValidVideo);
  };

  const isValidURL = React.useMemo(() => {
    if (!youtubeURL) {
      return true;
    }
    checkValidVideo();
    return YoutubeURLRegex.test(youtubeURL);
  }, [youtubeURL]);

  return (
    <div className="recipes-detail-page">
      <div className="recipes-detail-top">
        <div className="recipes-detail-top__left">
          <h2>
            <Link to={PAGES.recipes}>Recipes</Link>
            <span className="chev">
              <Icon name="chev-right-v2" />
            </span>
            <span className="truncate">Edit {recipeDetail?.name}</span>
          </h2>
          <Button className="btn-share">
            <Icon name="group" />
            Share
          </Button>
        </div>
        <div className="recipes-detail-top__right">
          <Button className="btn-how-to-use">
            <Icon name="info" />
            How to use Recipes
          </Button>
        </div>
      </div>

      <div className="recipes-detail-content">
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item name="name" label="Title">
            <Input
              className="input-outline"
              placeholder="Insert property URL here..."
            />
          </Form.Item>

          <Form.Item name="description" label="Description (optional)">
            <Input.TextArea autoSize className="input-outline" />
          </Form.Item>

          <div style={{ marginBottom: '20px' }}>
            <Collapse expandIconPosition="end">
              <Panel
                header="Learn basic formatting"
                key="1"
                extra={<span className="extra">Commonly used syntax</span>}
              >
                <div>
                  Optionally designate a line of text as a command by prefixing
                  it with the character. This will help visually separate
                  commands from other text on the page. Like this:
                </div>
              </Panel>
            </Collapse>
          </div>

          <Form.Item name="content" label="HomeScribe Commands">
            <Input.TextArea
              autoSize
              className="input-outline"
              placeholder=">write a blog post outline about how to feed gold fish"
            />
          </Form.Item>

          <div className="youtube-wrapper">
            <div className="input-wrapper">
              <Form.Item
                name="videoUrl"
                label="Youtube explainer video URL (optional)"
              >
                <Input
                  className="input-outline"
                  placeholder="https://www.youtube.com/watch?v=zsyIxPPmjRM"
                />
              </Form.Item>
            </div>
            {youtubeURL && isValidURL && isValidVideo ? (
              <iframe
                className="rounded-xl w-48 h-36"
                src={youtubeEmbedURL}
                title="Video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={false}
                width="100%"
                height="100%"
              ></iframe>
            ) : (
              <div className="video-preview">
                <p
                  className={`${!isValidURL || !isValidVideo ? 'invalid' : ''}`}
                >
                  {!isValidURL || !isValidVideo
                    ? 'Invalid video url'
                    : 'Video preview'}
                </p>
              </div>
            )}
          </div>

          <div className="action-wrapper">
            <Button className="btn-gradient btn-save" htmlType="submit">
              Save
            </Button>
            <Button className="btn-delete" onClick={showDeleteConfirm}>
              Delete
            </Button>
          </div>
        </Form>
      </div>
      {contextHolder}
    </div>
  );
};

export default RecipesEditPage;
