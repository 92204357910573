import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  size?: number;
}

const Loading = ({ size = 48 }: Props) => {
  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: size }} spin />} />
  );
};

export default Loading;
