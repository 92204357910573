import React from 'react';
import { Row, Col, Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import Icon from 'icons';
import PAGES from 'routes/constants';
import './index.scss';

const Home = () => {
  return (
    <div className="home-page">
      <div className="home-page__tool-section">
        <h2>New tools to help you create</h2>
        <Row gutter={[8, 8]}>
          <Col xs={1} xl={12}>
            <div className="card">
              <div className="card-inner">
                <div className="card-inner__top">
                  <Icon name="chat" />
                  <h2>Chat</h2>
                </div>

                <div className="card-inner__body">
                  <p>
                    Generate ideas, images, and content by chatting directly
                    with HomeScribe chat!
                  </p>
                </div>

                <div className="card-inner__bottom">
                  <Link to={PAGES.chat}>
                    <Button className="btn-chat">Go to Chat</Button>
                  </Link>
                </div>
              </div>
            </div>
          </Col>

          <Col xs={1} xl={12}>
            <div className="card">
              <div className="card-inner">
                <div className="card-inner__top">
                  <Icon name="workflow" />
                  <h2>Workflows</h2>
                </div>

                <div className="card-inner__body">
                  <p>
                    Worfklow - combine multiple recipes to reate blog posts,
                    social media campaigns, email campaigns, and rewrite
                    content.
                  </p>
                </div>

                <div className="card-inner__bottom">
                  <Button className="btn-chat">Go to Workflows</Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="home-page__more">
        <h2>More from HomeScribe</h2>
        <Row gutter={[8, 8]}>
          <Col xs={1} xl={12}>
            <div className="card-refer">
              <div className="card-refer__top">
                <h2>Community</h2>
                <Tag>Create a recipe → Win $100 gift card</Tag>
              </div>

              <div className="card-refer__body">
                <p>
                  Create a recipe for the community and get a chance to win a
                  $100 prize!
                </p>
              </div>

              <div className="card-refer__bottom">
                <Link to={PAGES.recipes}>
                  <Button className="btn-copy">
                    <Icon name="copy" />
                    Create a Recipe
                  </Button>
                </Link>
              </div>
            </div>
          </Col>

          <Col xs={1} xl={12}>
            <div className="card-refer">
              <div className="card-refer__top">
                <h2>Referrals</h2>
                <Tag>Invite Friends → Free 1 hour training session</Tag>
              </div>

              <div className="card-refer__body">
                <p>
                  For each referal you and your friend each get access to our
                  hourly training sessions to learn how to use AI.
                </p>
              </div>

              <div className="card-refer__bottom">
                <Button className="btn-copy">
                  <Icon name="copy" />
                  Copy share link
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
