import React from 'react';
import { Avatar, Button } from 'antd';
import { Link } from 'react-router-dom';
import { getRecipeCommunityURL } from 'routes/constants';
import './index.scss';
import Icon from 'icons';
import { Recipe } from 'services/api/type/recipe.type';
import { getStandForCharacter, limitString } from 'utils';
import ColorHash from 'color-hash';

const colorHash = new ColorHash();

type Props = {
  recipeItem: Recipe;
};

const RecipeItem = ({ recipeItem }: Props) => {
  return (
    <Link to={getRecipeCommunityURL(recipeItem.id)}>
      <div className="template-item">
        <div className="btn-favorite">
          <Button>
            <Icon name="star" />
          </Button>
        </div>

        <div className="template-info">
          <div className="template-logo">
            {/*<TemplateIcon name={'aida'} />*/}
            <Avatar
              style={{
                backgroundColor: colorHash.hex(
                  getStandForCharacter(recipeItem.owner.name),
                ),
                color: '#fff',
              }}
            >
              {getStandForCharacter(recipeItem.owner.name)}
            </Avatar>
          </div>

          <h3>{limitString(recipeItem.name, 100)}</h3>
          <p>{limitString(recipeItem.description)}</p>
        </div>
      </div>
    </Link>
  );
};

export default RecipeItem;
