import React, { useState } from 'react';
import _ from 'lodash';
import { Menu, Tooltip, Collapse } from 'antd';
import SkeletonLine from './skeletonLine';
import { Prompt } from 'services/api/type/prompt.type';
import './index.scss';

type Props = {
  groupData: any;
  prompts?: Prompt[];
  isLoading: boolean;
  onSetPrompt: any;
};

const { Panel } = Collapse;

const PromptChat = ({ groupData, isLoading, prompts, onSetPrompt }: Props) => {
  const [selectedMenu, setSelectedMenu] = useState<any>([]);
  const handleClickMenu = ({ key }: any) => {
    const dataPrompt = (prompts || []).find((item) => item.id === key);
    if (dataPrompt) {
      onSetPrompt(dataPrompt?.text);
    }
    setSelectedMenu([key]);
  };

  const renderLoading = () => {
    if (isLoading) {
      const menus = [
        {
          key: 1,
          label: <SkeletonLine width={200} height={20} />,
        },
        {
          key: 2,
          label: <SkeletonLine width={200} height={20} />,
        },
        {
          key: 3,
          label: <SkeletonLine width={200} height={20} />,
        },
        {
          key: 4,
          label: <SkeletonLine width={200} height={20} />,
        },
        {
          key: 5,
          label: <SkeletonLine width={200} height={20} />,
        },
        {
          key: 6,
          label: <SkeletonLine width={200} height={20} />,
        },
      ];
      return (
        <div className="prompt-chat-menu__item loading">
          <SkeletonLine width={230} height={25} />
          <div style={{ marginTop: '8px' }}>
            <Menu mode="vertical" items={menus} />
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="prompt-chat">
      <div className="prompt-chat-inner">
        {isLoading ? (
          <div style={{ marginTop: '8px', padding: '0 8px' }}>
            <SkeletonLine width={230} />
          </div>
        ) : (
          <h1 className="prompt-chat-inner__title">Chat Library</h1>
        )}

        <div className="prompt-chat-menu">
          {renderLoading()}
          {!_.isEmpty(groupData) && (
            <Collapse ghost>
              {Object.keys(groupData).map((key, index) => {
                const data: Prompt[] = groupData[key];
                const menus = data.map((item) => ({
                  key: item.id,
                  label: (
                    <Tooltip
                      placement="left"
                      title={
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                          {item.text}
                        </div>
                      }
                    >
                      <div style={{ display: 'flex', width: '100%' }}>
                        {item.name}
                      </div>
                    </Tooltip>
                  ),
                }));

                return (
                  <Panel
                    header={
                      <div className="prompt-chat-menu__item">
                        <h3 className="prompt-chat-menu__item__title">{key}</h3>
                      </div>
                    }
                    key={index}
                  >
                    <Menu
                      onClick={handleClickMenu}
                      selectedKeys={selectedMenu}
                      mode="vertical"
                      items={menus}
                    />
                  </Panel>
                );
              })}
            </Collapse>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromptChat;
