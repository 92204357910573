export enum RECIPE_STATUS {
  PRIVATE = 'private',
  PUBLIC = 'public',
}

export interface RecipeOwner {
  name: string;
}

export interface UserProfile {
  companyName: string;
  website: string;
}
export interface Recipe {
  id: number;
  content: string | null;
  createdAt: string;
  description: string;
  name: string;
  owner: RecipeOwner;
  ownerId: string;
  updatedAt: string;
  videoUrl: string | null;

  userProfile?: UserProfile;
}

export interface UpdateRecipe {
  content: string | null;
  description: string;
  name: string;
  videoUrl: string | null;
}
