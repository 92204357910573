export const convertStrPromptToArray = (data: string) => {
  const arr = data.split('\n');
  return arr
    .map((item) =>
      item
        .replace(/\n/g, '')
        .replace(/^[0-9]. /, '')
        .replace(/^[0-9]./, ''),
    )
    .filter((item) => !!item);
};
