import React from 'react';
import { Outlet, Route, Routes as Switch } from 'react-router-dom';
import PrivateRoutes from './privateRoutes';
import { ROUTES } from './routes';

import PAGES from './constants';
import MainLayout from 'layouts/main';
import SettingLayout from 'layouts/setting';
import CommunityLayout from 'layouts/community';

function WrapPrivateRoute(Component: React.FC) {
  return (
    <PrivateRoutes>
      <Component />
    </PrivateRoutes>
  );
}

function WrapOutsideLayout(Component: React.FC) {
  return (
    <div>
      <Component />
    </div>
  );
}

export const Routes = () => {
  return (
    <Switch>
      {ROUTES.map(
        ({ elem: Component, layout: CustomLayout, ...item }, index) => {
          if (item.isPrivate) {
            const pagesSettingLayout = [
              PAGES.settings,
              PAGES.billingSetting,
              PAGES.profileSetting,
            ];
            const communityLayout = [PAGES.recipeDetail];
            const noMenuLayout = [PAGES.documentDetail];

            let Layout = MainLayout;
            if (pagesSettingLayout.includes(item.path)) {
              Layout = SettingLayout;
            }
            if (communityLayout.includes(item.path)) {
              Layout = CommunityLayout;
            }
            if (noMenuLayout.includes(item.path)) {
              Layout = () => (
                <div className="blank-layout">
                  <Outlet />
                </div>
              );
            }

            if (CustomLayout) {
              Layout = CustomLayout as any;
            }

            return (
              <Route path={PAGES.home} key={index} element={<Layout />}>
                <Route path={item.path} element={WrapPrivateRoute(Component)} />
              </Route>
            );
          }

          return (
            <Route
              path={item.path}
              key={index}
              element={WrapOutsideLayout(Component)}
            />
          );
        },
      )}
    </Switch>
  );
};
