import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'icons';
import PAGES from 'routes/constants';
import './index.scss';

const CommunityHeader = () => {
  return (
    <header className="community-header">
      <span className="logo">
        <Link to={PAGES.home}>
          <Icon name="logo" />
        </Link>
      </span>
    </header>
  );
};

export default CommunityHeader;
