import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonTitle = (props: any) => (
  <ContentLoader
    speed={2}
    width={500}
    height={40}
    viewBox="0 0 500 40"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="150" height="20" />
    <rect x="0" y="30" rx="2" ry="2" width="500" height="10" />
  </ContentLoader>
);

export default SkeletonTitle;
