import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  EventsExtension,
  HardBreakExtension,
  MarkdownExtension,
} from 'remirror/extensions';
import {
  EditorComponent,
  Remirror,
  useEditorEvent,
  useKeymap,
  useRemirror,
  useRemirrorContext,
} from '@remirror/react';
import { ClickHandlerState } from '@remirror/extension-events/dist-types/events-extension';
import { HighlightExtension } from 'components/common/remirror-editor/HighlightExtension';
import { convertTextToMarkdown } from 'components/common/remirror-editor/helper';
import './index.scss';

const Commands = (props: { onEnter?: (text: string) => void }) => {
  const { onEnter } = props;
  const { commands, getState, helpers } = useRemirrorContext({
    autoUpdate: true,
  });

  const handleClick = (event: any, state: ClickHandlerState) => {
    const { markRanges } = state;
    if (!isEmpty(markRanges)) {
      const from = markRanges[0].from;
      const to = markRanges[0].to;
      // Use this one to force select
      commands.selectText({
        from,
        to: from,
      });
      commands.selectText({
        from,
        to,
      });
    }
    return false;
  };

  const handleOnChange = (payload: {
    from: number;
    to: number;
    text: string;
  }) => {
    const { from } = payload;
    const state = getState();
    const data = state.doc.nodeAt(from);
    const mark = data?.marks[0];
    if (mark?.type?.name === HighlightExtension.getName()) {
      commands.toggleMark(mark);
    }
  };

  useEditorEvent('click', handleClick);
  useEditorEvent('textInput', handleOnChange);

  const onSave = useCallback(() => {
    // Prevents any further key handlers from being run.
    onEnter && onEnter(helpers.getMarkdown());
    return true;
  }, []);

  const shiftEnter = useCallback(() => {
    commands.insertHardBreak();
    // Prevents any further key handlers from being run.
    return true;
  }, []);

  useKeymap('Enter', onSave);
  useKeymap('Shift-Enter', shiftEnter);

  return null;
};

export interface RemirrorEditorProps {
  initialContent?: string;
  placeholder?: string;
  onChange?: (text: string) => void;
  onEnter?: (text: string) => void;
}

export interface RemirrorEditorRefProps {
  setValue: (text: string) => void;
  getValue: () => string;
  focus: () => void;
}

const RemirrorEditor = forwardRef<RemirrorEditorRefProps, RemirrorEditorProps>(
  (props: RemirrorEditorProps, ref) => {
    const { initialContent, onEnter, placeholder, onChange } = props;
    const { manager, state, getContext } = useRemirror({
      extensions: () => [
        new HardBreakExtension(),
        new MarkdownExtension(),
        new HighlightExtension(),
        new EventsExtension(),
      ],

      // Set the initial content.
      content: convertTextToMarkdown(initialContent || ''),

      // Place the cursor at the start of the document. This can also be set to
      // `end`, `all` or a numbered position.
      selection: 'end',

      // Set the string handler which means the content provided will be
      // automatically handled as html.
      // `markdown` is also available when the `MarkdownExtension`
      // is added to the editor.
      stringHandler: 'markdown',
    });

    useImperativeHandle(
      ref,
      () => ({
        setValue: (text: string) =>
          getContext()?.setContent(convertTextToMarkdown(text)),
        getValue: () => getContext()?.helpers.getMarkdown() as string,
        focus: () => getContext()?.focus(),
      }),
      [getContext],
    );

    const handleOnChange = (parameter: any) => {
      if (!parameter.tr?.docChanged) {
        // Insert text into the editor via a new state.
        return;
      }
      onChange && onChange(parameter.helpers.getMarkdown());
    };

    return (
      <Remirror
        placeholder={placeholder}
        onChange={handleOnChange}
        manager={manager}
        initialContent={state}
      >
        <EditorComponent />
        <Commands onEnter={onEnter} />
      </Remirror>
    );
  },
);

export default RemirrorEditor;
