import React, { useState } from 'react';

interface Props {
  text: string;
  maxLength: number;
}
function SeeMoreText({ text, maxLength }: Props) {
  const [showAllText, setShowAllText] = useState(false);

  if (text.length <= maxLength) {
    return <p>{text}</p>;
  }

  return (
    <div>
      <p>
        {showAllText ? text : text.slice(0, maxLength)}...
        <a onClick={() => setShowAllText(!showAllText)}>
          {showAllText ? 'see less' : 'see more'}
        </a>
      </p>
    </div>
  );
}

export default SeeMoreText;
