import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from 'icons';
import PAGES from 'routes/constants';

const MainMenu = () => {
  const location = useLocation();
  const mainMenu = React.useMemo(() => {
    return [
      {
        key: PAGES.home,
        label: (
          <Link to={PAGES.home} className="menu-item">
            <Icon name="home" />
            Dashboard
          </Link>
        ),
      },
      {
        key: PAGES.chat,
        label: (
          <Link to={PAGES.chat} className="menu-item">
            <Icon name="chat" />
            Chat
          </Link>
        ),
      },
      // {
      //   key: PAGES.templates,
      //   label: (
      //     <Link to={PAGES.templates} className="menu-item">
      //       <Icon name="template" />
      //       Templates
      //     </Link>
      //   ),
      // },
      // {
      //   key: PAGES.documents,
      //   label: (
      //     <div className="d-flex-center-between">
      //       <Link to={PAGES.documents} className="menu-item btn-docs flex-full">
      //         <Icon name="document" />
      //         Document
      //       </Link>
      //       <Link to={PAGES.addDocument} className="menu-item btn-add">
      //         <Icon name="plus" />
      //       </Link>
      //     </div>
      //   ),
      // },
      {
        key: PAGES.recipes,
        label: (
          <Link to={PAGES.recipes} className="menu-item">
            <Icon name="recipe" />
            Recipes
          </Link>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return mainMenu;
};

export default MainMenu;
