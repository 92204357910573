export enum Actions {
  RE_WRITE = 'rewrite',
  EXPLAIN_TO_CHILD = 'explainToChild',
  WRITE_NEXT_SENTENCE = 'write_next_sentence',
  GET_PROMPTS = 'get_prompts',
}

export interface GetPromptsCompletionData {
  content: string;
}

export interface CompletionRequest {
  data: GetPromptsCompletionData;
  action: Actions;
}
