import baseApi from '../base.api';
import { paths } from '../paths';
import type { Document } from '../type/document.type';
import { CompletionRequest } from '~/services/api/type/ai.type';

export const createCompletion = (
  data: CompletionRequest,
): Promise<Document[]> => {
  return baseApi.post(paths.aiCreateCompletion(), data);
};
