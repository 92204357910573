import createStorage from './createStorage';
import { ChatContent } from '~/models/chat-content';

const instance = createStorage('chat');
const KEY_STORAGE = 'chat';

export const setChatContents = (data: any) =>
  instance.setValue(KEY_STORAGE, data);

export const getChatContents = (): ChatContent[] =>
  instance.getValue(KEY_STORAGE);
