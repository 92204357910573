import React from 'react';

import { ReactComponent as Aida } from 'assets/images/template/aida.svg';

export type IconType = 'aida';

export enum ICON {
  AIDA = 'aida',
}

interface Props {
  name: IconType;
}

const Icons = {
  [ICON.AIDA]: <Aida />,
};

function TemplateIcon({ name }: Props) {
  return Icons[name] || null;
}

export default React.memo(TemplateIcon);
