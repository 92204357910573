import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';

import Icon from 'icons';
import PAGES from 'routes/constants';
// import { useUser } from 'stores/auth/auth.selector';
import './index.scss';

const SettingMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const user = useUser();
  const [selectedMenu, setSelectedMenu] = useState([location.pathname]);

  const profileMenu = React.useMemo(() => {
    return [
      {
        key: PAGES.profileSetting,
        label: (
          <Link to={PAGES.profileSetting} className="menu-item">
            Profile
          </Link>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const productMenu = React.useMemo(() => {
    return [
      {
        key: PAGES.integrationSetting,
        label: (
          <Link to={PAGES.integrationSetting} className="menu-item">
            Integrations
          </Link>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const workSpaceMenu = React.useMemo(() => {
    return [
      {
        key: PAGES.generalSetting,
        label: (
          <Link to={PAGES.generalSetting} className="menu-item">
            General
          </Link>
        ),
      },
      {
        key: PAGES.billingSetting,
        label: (
          <Link to={PAGES.billingSetting} className="menu-item">
            Billing
          </Link>
        ),
      },
      {
        key: PAGES.teamSetting,
        label: (
          <Link to={PAGES.teamSetting} className="menu-item">
            Team
          </Link>
        ),
      },
      {
        key: PAGES.usageSetting,
        label: (
          <Link to={PAGES.usageSetting} className="menu-item">
            Usage
          </Link>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onboardingMenu = React.useMemo(() => {
    return [
      {
        key: PAGES.onboardingSetting,
        label: (
          <Link to={PAGES.onboardingSetting} className="menu-item">
            Welcome to Homescribe
          </Link>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const keys = [];
    // if (location.pathname === PAGES.addDocument) {
    //   keys.push(PAGES.documents);
    // }

    // if (matchPath(PAGES.templateDetail, location.pathname)) {
    //   keys.push(PAGES.templates);
    // }

    // if (matchPath(PAGES.recipeDetail, location.pathname)) {
    //   keys.push(PAGES.recipes);
    // }

    keys.push(location.pathname);
    setSelectedMenu(keys);
  }, [location]);

  return (
    <aside className="setting-menu">
      <div className="menu-wrapper">
        <div className="menu-top">
          <div className="menu-top__logo">
            <div onClick={() => navigate(-1)}>
              <Icon name="arrow-left" />
            </div>
            {/* <Tag>Free</Tag> */}
          </div>
        </div>

        <nav className="menu-body">
          <div className="menu-body__section">
            <p className="menu-body__section__title">Personal settings</p>
            <Menu
              mode="vertical"
              items={profileMenu}
              selectedKeys={selectedMenu}
            />
          </div>

          <div className="menu-body__section">
            <p className="menu-body__section__title">Product settings</p>
            <Menu
              mode="vertical"
              items={productMenu}
              selectedKeys={selectedMenu}
            />
          </div>

          <div className="menu-body__section">
            <p className="menu-body__section__title">Workspace settings</p>
            <Menu
              mode="vertical"
              items={workSpaceMenu}
              selectedKeys={selectedMenu}
            />
          </div>

          <div className="menu-body__section">
            <p className="menu-body__section__title">Onboarding</p>
            <Menu
              mode="vertical"
              items={onboardingMenu}
              selectedKeys={selectedMenu}
            />
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default SettingMenu;
