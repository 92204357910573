import React from 'react';
import classnames from 'classnames';
import ColorHash from 'color-hash';
import { Button, Avatar, message } from 'antd';
import Icon from 'icons';
import './index.scss';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import tsx from 'react-syntax-highlighter/dist/cjs/languages/prism/tsx';
import typescript from 'react-syntax-highlighter/dist/cjs/languages/prism/typescript';
import scss from 'react-syntax-highlighter/dist/cjs/languages/prism/scss';
import bash from 'react-syntax-highlighter/dist/cjs/languages/prism/bash';
import markdown from 'react-syntax-highlighter/dist/cjs/languages/prism/markdown';
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
import go from 'react-syntax-highlighter/dist/cjs/languages/prism/go';
import { useUser } from 'stores/auth/auth.selector';
import { clipboard, getShortenName } from 'utils';
import ReactGA from 'react-ga4';

SyntaxHighlighter.registerLanguage('tsx', tsx);
SyntaxHighlighter.registerLanguage('typescript', typescript);
SyntaxHighlighter.registerLanguage('scss', scss);
SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('markdown', markdown);
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('go', go);

interface Props {
  content: string;
  isUser?: boolean;
  user?: any;
  isLastItem?: boolean;
  isLoading?: boolean;
  handleResendMessage?: () => void;
}

const colorHash = new ColorHash();

const CodeBlock = {
  code({ inline, className, children, ...props }: any) {
    const [messageApi, contextHolder] = message.useMessage();
    const copyToClipboard = async () => {
      await clipboard(children).then(() => {
        messageApi.open({
          type: 'success',
          content: 'Copied to clipboard!',
        });
      });
      ReactGA.event({
        category: 'Chat',
        action: 'code-clipboard',
        label: 'Copy code to clipboard',
      });
    };
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <div className="syntax-highlighter">
        <Button onClick={copyToClipboard} className="btn-action btn-clipboard">
          Copy
        </Button>
        <SyntaxHighlighter
          {...props}
          style={coldarkDark}
          customStyle={{
            fontSize: '14px',
          }}
          language={match[1]}
          PreTag="div"
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
        {contextHolder}
      </div>
    ) : (
      <code {...props} className={className + ' single-code'}>
        {children}
      </code>
    );
  },
};

const ChatItem = ({
  isUser,
  content,
  isLastItem,
  isLoading,
  handleResendMessage,
}: Props) => {
  const user = useUser();
  const [messageApi, contextHolder] = message.useMessage();

  const chatItemClass = classnames('chat-item', {
    user: isUser,
    show: isLastItem && !isUser,
  });

  const copyToClipboard = async () => {
    await clipboard(content).then(() => {
      messageApi.open({
        type: 'success',
        content: 'Copied to clipboard!',
      });
    });
    ReactGA.event({
      category: 'Chat',
      action: 'clipboard',
      label: 'Copy to clipboard',
    });
  };

  const sendRateMessage = async (rate: boolean) => {
    await clipboard(content).then(() => {
      messageApi.open({
        type: 'success',
        content: 'Thanks for your rating!',
      });
    });
    ReactGA.event({
      category: 'Chat',
      action: 'rating',
      label: rate ? 'Like' : 'Dislike',
    });
  };

  return (
    <li className={chatItemClass}>
      <div className="chat-item__content">
        <div
          className={`chat-item__message ${isLoading ? 'animated-text' : ''}`}
        >
          <ReactMarkdown components={CodeBlock} remarkPlugins={[remarkGfm]}>
            {content}
          </ReactMarkdown>
        </div>
        <div className="chat-item__action">
          {!isUser && (
            <>
              <Button
                onClick={() => sendRateMessage(true)}
                className="btn-action"
              >
                <Icon name="thumb" />
              </Button>
              <Button
                onClick={() => sendRateMessage(false)}
                className="btn-action"
              >
                <Icon name="down-thumb" />
              </Button>
              <Button onClick={handleResendMessage} className="btn-action">
                <Icon name="reset" />
              </Button>
              <Button onClick={copyToClipboard} className="btn-action">
                <Icon name="duplicate" />
              </Button>
            </>
          )}
        </div>
      </div>

      <div className="chat-item__user">
        {isUser ? (
          <Avatar
            style={{
              backgroundColor: colorHash.hex(getShortenName(user)),
              color: '#fff',
            }}
          >
            {getShortenName(user)}
          </Avatar>
        ) : (
          <span className="ant-avatar bot">
            <Icon name="homescribe-logo" />
          </span>
        )}
      </div>
      {contextHolder}
    </li>
  );
};

export default ChatItem;
