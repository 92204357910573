export enum TAG_VALUE {
  ALL = 'all',
  SALES = 'sales',
  MARKETING = 'marketing',
  ADS = 'ads',
  BLOG = 'blog',
  EMAIL = 'email',
  SOCIAL = 'social',
  SEO = 'seo',
  WEBSITE = 'website',
}

export const TAGS = [
  {
    label: 'All',
    value: TAG_VALUE.ALL,
  },
  {
    label: 'Sales',
    value: TAG_VALUE.SALES,
  },
  {
    label: 'Marketing',
    value: TAG_VALUE.MARKETING,
  },
  {
    label: 'Ads',
    value: TAG_VALUE.ADS,
  },
  {
    label: 'Blog',
    value: TAG_VALUE.BLOG,
  },
  {
    label: 'SEO',
    value: TAG_VALUE.SEO,
  },
  {
    label: 'Social Media',
    value: TAG_VALUE.SOCIAL,
  },
  {
    label: 'Website',
    value: TAG_VALUE.WEBSITE,
  },
];
