import React, { useState } from 'react';
import { Button, Collapse, Form, Input, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import Icon from 'icons';
import PAGES from 'routes/constants';
import { YoutubeURLRegex } from 'constants/regex';
import { convertYoutubeEmbed, validYoutubeVideoId } from 'utils';

import './index.scss';
import { useMutation } from '@tanstack/react-query';
import API from 'services/api';
import { UpdateRecipe } from 'services/api/type/recipe.type';

const { Panel } = Collapse;

const RecipesNewPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const { mutateAsync: updateRecipeAsync } = useMutation({
    mutationFn: (data: UpdateRecipe) => {
      return API.recipe.createRecipe(data);
    },
  });

  const youtubeURL = Form.useWatch('videoUrl', form);
  const [isValidVideo, setIsValidVideo] = useState(false);

  const youtubeEmbedURL = React.useMemo(() => {
    if (!youtubeURL) {
      return '';
    }
    return convertYoutubeEmbed(youtubeURL);
  }, [youtubeURL]);

  const checkValidVideo = async () => {
    const isValidVideo = await validYoutubeVideoId(youtubeURL);
    setIsValidVideo(isValidVideo);
  };

  const isValidURL = React.useMemo(() => {
    if (!youtubeURL) {
      return true;
    }
    checkValidVideo();
    return YoutubeURLRegex.test(youtubeURL);
  }, [youtubeURL]);

  const onFinish = async (payload: any) => {
    try {
      await updateRecipeAsync(payload);
      messageApi.open({
        type: 'success',
        content: 'Create recipe successful',
      });
      navigate(PAGES.recipes);
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error?.message,
      });
    }
  };

  return (
    <div className="recipes-detail-page">
      <div className="recipes-detail-top">
        <div className="recipes-detail-top__left">
          <h2>
            <Link to={PAGES.recipes}>Recipes</Link>
            <span className="chev">
              <Icon name="chev-right-v2" />
            </span>
            <span className="truncate">New recipe</span>
          </h2>
          <Button className="btn-share">
            <Icon name="group" />
            Share
          </Button>
        </div>
        <div className="recipes-detail-top__right">
          <Button className="btn-how-to-use">
            <Icon name="info" />
            How to use Recipes
          </Button>
        </div>
      </div>

      <div className="recipes-detail-content">
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item name="name" label="Title">
            <Input
              className="input-outline"
              placeholder="Insert property URL here..."
            />
          </Form.Item>

          <Form.Item name="description" label="Description (optional)">
            <Input.TextArea autoSize className="input-outline" />
          </Form.Item>

          <div style={{ marginBottom: '20px' }}>
            <Collapse expandIconPosition="end">
              <Panel
                header="Learn basic formatting"
                key="1"
                extra={<span className="extra">Commonly used syntax</span>}
              >
                <div>
                  Optionally designate a line of text as a command by prefixing
                  it with the character. This will help visually separate
                  commands from other text on the page. Like this:
                </div>
              </Panel>
            </Collapse>
          </div>

          <Form.Item name="content" label="HomeScribe Commands">
            <Input.TextArea
              autoSize
              className="input-outline"
              placeholder=">write a blog post outline about how to feed gold fish"
            />
          </Form.Item>

          <div className="youtube-wrapper">
            <div className="input-wrapper">
              <Form.Item
                name="videoUrl"
                label="Youtube explainer video URL (optional)"
              >
                <Input
                  className="input-outline"
                  placeholder="https://www.youtube.com/watch?v=zsyIxPPmjRM"
                />
              </Form.Item>
            </div>
            {youtubeURL && isValidURL && isValidVideo ? (
              <iframe
                className="rounded-xl w-48 h-36"
                src={youtubeEmbedURL}
                title="Video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={false}
                width="100%"
                height="100%"
              ></iframe>
            ) : (
              <div className="video-preview">
                <p
                  className={`${!isValidURL || !isValidVideo ? 'invalid' : ''}`}
                >
                  {!isValidURL || !isValidVideo
                    ? 'Invalid video url'
                    : 'Video preview'}
                </p>
              </div>
            )}
          </div>

          <div className="action-wrapper">
            <Button className="btn-gradient btn-save" htmlType="submit">
              Save
            </Button>
            <Button className="btn-delete">Delete</Button>
          </div>
        </Form>
      </div>
      {contextHolder}
    </div>
  );
};

export default RecipesNewPage;
