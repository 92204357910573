import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  clearToken,
  getToken,
  setProfile,
  setToken,
  setRefreshToken,
  getRefreshToken,
} from 'services/storages/userStorage';
import baseApi from 'services/api/base.api';
import get from 'lodash/get';
import { AuthToken } from 'models/user';

export interface Profile {
  category: string;
  companyName: string;
  jobTitle: string;
  website: string;
  firstName: string;
  fullName?: string;
  lastName: string;
}

export interface UserProfile {
  id: string;
  email: string;
  photo_url: string;
  firstName: string;
  full_name?: string;
  lastName: string;
  profile: Profile;
}
interface InitStateInterface {
  user: any;
  token: AuthToken | null;
  // error: string;
  // loading: boolean;
}

const initialState = {
  user: null,
  token: getToken(),
  refreshToken: getRefreshToken(),
  // error: null,
  // loading: false,
} as InitStateInterface;

baseApi.setToken(getToken()?.access_token);
baseApi.setClearCallback(clearToken);

const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<any>) {
      const user = get(state, 'user', {});
      const newUser = { ...user, ...action.payload };
      setProfile(newUser);
      state.user = newUser;
    },
    saveToken: (state, action: PayloadAction<AuthToken>) => {
      const authToken = action.payload;
      baseApi.setToken(authToken.access_token);
      baseApi.setClearCallback(clearToken);
      setToken(authToken);
      state.token = authToken;
    },
    saveUser: (state, action: PayloadAction<any>) => {
      const { access_token, refresh_token, user: userData } = action.payload;

      const user = get(state, 'user', {});
      const newUser = { ...user, ...userData };
      setProfile(newUser);

      baseApi.setToken(access_token);
      baseApi.setClearCallback(clearToken);
      setToken(action.payload);
      setRefreshToken(refresh_token);
      state.user = { ...userData, ...userData?.user_metadata };
      state.token = access_token;
      // state.error = null
      // state.loading = false
    },
    logout: (state) => {
      clearToken();
      state.user = null;
      state.token = null;
      // state.error = null
      // state.loading = false
    },
  },
});

export const { updateUser, saveToken, saveUser, logout } = authSlice.actions;
export default authSlice.reducer;
