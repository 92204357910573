import React from 'react';
import './index.scss';

type Props = {
  tag: string;
  content: string | React.ReactNode;
};
const Legend = ({ tag, content }: Props) => {
  return (
    <div className="legend">
      <div className="legend-tag">{tag}</div>
      <p className="legend-content">{content}</p>
    </div>
  );
};

export default Legend;
