import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Chat from 'components/chat';
import PromptChat from 'components/common/prompt-chat';
import API from 'services/api';
import QUERY_KEYS from 'services/api/queryKeys';
import './index.scss';

const ChatPage = () => {
  const [prompt, setPrompt] = useState('');
  const { data: prompts, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_PROMPTS],
    queryFn: API.prompt.getPrompts,
  });

  const groupData = React.useMemo(() => {
    const result: any = {};
    (prompts || []).forEach((prompt) => {
      for (const category of prompt.categories) {
        if (!result[category]) {
          result[category] = [];
        }
        result[category].push(prompt);
      }
    });
    return result;
  }, [prompts]);

  return (
    <div className="chat-page">
      <Chat prompt={prompt} />
      <PromptChat
        groupData={groupData}
        isLoading={isLoading}
        prompts={prompts}
        onSetPrompt={setPrompt}
      />
    </div>
  );
};

export default ChatPage;
