/**
 * Combine all reducers in this file and export the combined reducers.
 */

// import { combineReducers } from "redux";
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/auth.reducer';
// import companyReducer from "./company/company.reducer";
// import syncReducer from "./sync/sync.reducer";
// import { hiveApi } from "services/api/module/hive.api";

/**
 * Creates the main reducer with the dynamically injected ones
 */
const rootReducer = combineReducers({
  authReducer,
  // companyReducer,
  // syncReducer,
  // [hiveApi.reducerPath]: hiveApi.reducer,
});
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
