import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { Routes } from './routes';
import { Layout } from 'antd';

function App() {
  useEffect(() => {
    ReactGA.initialize('G-VHGTKEPPFE'); // Replace with your GA4 measurement ID
    // Track page view on mount
  }, []);

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: location.pathname,
    });
  }, [location]);

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Layout className="app-content">
        <Routes />
      </Layout>
    </React.Suspense>
  );
}

export default App;
