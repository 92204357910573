import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import Icon from 'icons';
import API from 'services/api';
import { saveUser } from 'stores/auth/auth.reducer';

import PAGES from 'routes/constants';
import {
  LoginType,
  RegisterType,
  VerifyEmailType,
  VerifyEmailTypeInside,
} from 'services/api/type/user.type';
import './index.scss';

type Props = {
  title: string;
  actionText: string;
  email: string;
  firstName?: string;
  lastName?: string;
  type: 'magiclink' | 'signup';
};

const VerifyCode = ({
  title,
  actionText,
  email,
  type,
  firstName,
  lastName,
}: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const { mutateAsync: login, isLoading: loadingResend } = useMutation({
    mutationFn: (data: LoginType) => {
      return API.user.login(data);
    },
  });

  const { mutateAsync: register, isLoading: loadingRegister } = useMutation({
    mutationFn: (data: RegisterType) => {
      return API.user.register(data);
    },
  });

  const { mutateAsync: verifyEmail, isLoading: loadingVerify } = useMutation({
    mutationFn: (data: VerifyEmailType) => {
      return API.user.verifyEmail(data);
    },
  });

  const handleResendCode = async () => {
    try {
      if (type === 'signup') {
        await register({
          email,
          firstName: String(firstName),
          lastName: String(lastName),
        });
      } else {
        await login({
          email,
        });
      }
      messageApi.open({
        type: 'success',
        content: 'Send code successfully',
      });
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error?.message,
      });
    }
  };

  const onFinish = async (values: any) => {
    const { code } = values;
    try {
      const result = await verifyEmail({
        type: type as VerifyEmailTypeInside,
        email,
        code,
      });
      dispatch(saveUser(result));
      window.open(PAGES.home, '_self');
    } catch (error: any) {
      messageApi.open({
        type: 'error',
        content: error?.message,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="verify-code">
      <p className="verify-code__title">{title}</p>
      <p className="verify-code__desc">
        Enter the code we sent to {email} {actionText}
      </p>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="form-wrapper">
          <div className="form__resend">
            <Form.Item
              label="Code"
              name="code"
              rules={[{ required: true, message: 'Please input your code!' }]}
            >
              <Input placeholder="Enter code" className="input-outline" />
            </Form.Item>

            <div className="form__resend__action">
              <Button
                className="btn-primary w-full large"
                type="primary"
                htmlType="submit"
                loading={loadingVerify}
              >
                <span style={{ marginRight: '8px' }}>Continue</span>
                <Icon name="arrow-right" />
              </Button>
            </div>
          </div>

          <div className="extra-action">
            <span className="normal">Didn&apos;t get the code?</span>
            <Button
              className="btn-resend"
              onClick={handleResendCode}
              loading={loadingResend || loadingRegister}
            >
              Resend code
            </Button>
            {/* <Link className="action-item" to={PAGES.signup}>
              <Icon name="close" />
              <span>Try a different email</span>
            </Link> */}
          </div>
        </div>
      </Form>
      {contextHolder}
    </div>
  );
};

export default VerifyCode;
