import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { debounce } from 'lodash';
import { Button, Form, Switch, Tag } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import Icon from 'icons';
import API from 'services/api';
import Socket from 'services/socket';
import { Actions } from 'services/api/type/ai.type';

import ChatItem from './chat-item';
import './index.scss';
import { convertStrPromptToArray } from 'components/chat/chat.helper';
import {
  DEFAULT_PROMPTS,
  MAX_LENGTH,
  MAX_LENGTH_ROW,
} from 'components/chat/chat.constant';
import { useChatContent } from 'hooks/useChatContent';
import { ChatRole } from 'models/chat-content';
import RemirrorEditor, {
  RemirrorEditorRefProps,
} from 'components/common/remirror-editor/RemirrorEditor';

type Props = {
  prompt?: string;
};

const Chat = ({ prompt }: Props) => {
  const scrollView = useRef<any>(null);
  const chatViewRef = useRef<any>(null);
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const refEditor = useRef<RemirrorEditorRefProps>();
  const socketInstance = useRef<Socket>(Socket.getInstance());

  const { contents, setContents } = useChatContent();
  const [showJumpButton, setShowJumpButton] = useState(false);

  const [typingContent, setTypingContents] = useState<string>('');
  // eslint-disable-next-line
  const [prompts, setPrompts] = useState<string[]>(DEFAULT_PROMPTS); 

  const recipe = searchParams.get('recipe');

  const { mutateAsync: fetchPrompt } = useMutation({
    mutationFn: (content: string) =>
      API.ai.createCompletion({
        action: Actions.GET_PROMPTS,
        data: { content },
      }),
  });

  useEffect(() => {
    if (recipe) {
      refEditor.current?.setValue(recipe);
    }
  }, [recipe]);

  useEffect(() => {
    if (prompt) {
      refEditor.current?.setValue(prompt);
    }
  }, [prompt]);

  useEffect(() => {
    const socketRefValue = socketInstance.current;
    socketInstance.current.open();
    const offSteamEvent = socketInstance.current.on(
      'stream-chat',
      (data: any) => {
        setTypingContents((text) => {
          if (text.length > 0 && text.length < 200) {
            chatViewRef.current.scrollIntoView();
          }
          // Update scroll
          handleChatScroll();
          return text + data.text;
        });
      },
    );

    const offEndSteamEvent = socketInstance.current.on(
      'end-stream-chat',
      async (data: any) => {
        setContents((items) => [
          ...items,
          {
            role: ChatRole.assistant,
            name: 'HomeBuilderAI',
            content: data.content,
          },
        ]);
        setTypingContents('');
        await handleFetchPrompt(data.content);
      },
    );
    return () => {
      offSteamEvent();
      offEndSteamEvent();
      socketRefValue.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFinish = (message?: string) => {
    const content = message || (refEditor.current?.getValue() as string);
    ReactGA.event({
      category: 'Chat',
      action: 'SendMessage',
      label: 'Send message',
    });
    setContents((items) => [
      ...items,
      { role: ChatRole.user, name: 'user', content },
    ]);
    socketInstance.current.sendEvent('streamChatCompletion', {
      data: [
        {
          role: ChatRole.assistant,
          content: 'text in Markdown format',
          name: 'HomeBuilderAI',
        },
        {
          role: ChatRole.assistant,
          content:
            "My name is HomeBuilderAI. I'm a writing assistant powered by proprietary AI models",
          name: 'HomeBuilderAI',
        },
        ...contents.slice(-MAX_LENGTH_ROW),
        { role: ChatRole.user, content },
      ],
    });
    refEditor.current?.setValue('');
    chatViewRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleFetchPrompt = async (content?: string) => {
    const lastContent =
      contents.length > 0 ? contents[contents.length - 1].content : 'anything';
    const data = (await fetchPrompt(content || lastContent)) as any;
    setPrompts(convertStrPromptToArray(data[0].text));
  };

  const handleClearChat = () => {
    setContents([]);
    setPrompts(DEFAULT_PROMPTS);
  };

  const handleIncludeGoogle = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  const handleKeyDown = () => {
    form.submit();
  };

  const handleChatScroll = debounce(() => {
    const { scrollTop, scrollHeight, clientHeight } = scrollView.current;

    setShowJumpButton(scrollHeight - scrollTop > clientHeight + 150);
    // Perform any other actions with the debounced value
  }, 300);

  const handleJumpButtonClick = () => {
    setShowJumpButton(false);
    chatViewRef.current.scrollIntoView();
  };

  // const btnCollapseClass = classnames('btn-collapse btn-transparent', {
  //   show: showPrompts,
  // });
  // const chatSuggestionClass = classnames('chat-tool__suggestion', {
  //   show: showPrompts,
  // });

  return (
    <div className="chat-component">
      <div className="chat-wrapper">
        <div className="chat-area" ref={scrollView} onScroll={handleChatScroll}>
          <div className="chat-area-inner">
            <div className="logo-area">
              <h1>
                <span className="title">HomeScribe AI Chat</span>
                <span className="tag">
                  <Tag>Beta</Tag>
                </span>
              </h1>
            </div>

            <ul className="chat-box">
              {contents.map((item, index) => (
                <ChatItem
                  key={index}
                  isLastItem={!typingContent && contents.length === index + 1}
                  isUser={item.role === ChatRole.user}
                  content={item.content}
                  handleResendMessage={() =>
                    handleFinish(contents[index - 1].content)
                  }
                />
              ))}
              {typingContent && (
                <ChatItem isLastItem isLoading content={typingContent} />
              )}
            </ul>
          </div>
          <span ref={chatViewRef} />
        </div>
        {showJumpButton && (
          <div className="jump-to-latest" onClick={handleJumpButtonClick}>
            <p className="btn">Jump to Latest</p>
          </div>
        )}

        <div className="chat-tool fade-up">
          <Form
            form={form}
            onFinish={() => handleFinish()}
            className="chat-form"
          >
            <small className="limit-character">0 / {MAX_LENGTH}</small>
            <div className="form-item">
              <RemirrorEditor
                onEnter={handleKeyDown}
                placeholder="Ask HomeScribe to write you a SEO-Enhanced Property Description for your website"
                ref={refEditor as any}
              />
            </div>

            <div className="form-action">
              <div className="form-action__left">
                <Button
                  onClick={handleClearChat}
                  className="btn-clear btn-outline"
                >
                  <Icon name="close" />
                  Clear chat
                </Button>
              </div>

              <div className="form-action__right">
                <div className="include-gg">
                  <Switch onChange={handleIncludeGoogle} />
                  <span className="desc">Generate Magic!</span>
                </div>
                <Button className="btn-send btn-gradient" htmlType="submit">
                  <Icon name="send" />
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Chat;
