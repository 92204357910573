import React, { useCallback } from 'react';
import { Divider } from 'antd';
import ReactQuill from 'react-quill';
import debounce from 'lodash/debounce';

import EditorIcon from 'icons/editor';
import './index.scss';

interface Props {
  placeholder?: string;
  value?: any;
  onChange?: any;
}
const Editor = ({ placeholder, value, onChange }: Props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeFunc = useCallback(
    debounce((editor) => onChange(editor.getContents()), 1000),
    [],
  );
  const handleChangeValue = (
    value: string,
    delta: any,
    source: any,
    editor: any,
  ) => {
    onChangeFunc(editor);
  };

  return (
    <>
      <CustomToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChangeValue}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
      />
    </>
  );
};

export default Editor;

function undo() {
  // @ts-ignore
  return this.quill.history.undo();
}

function redo() {
  // @ts-ignore
  return this.quill.history.redo();
}

const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undo,
      redo: redo,
    },
  },
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const CustomToolbar = () => (
  <div id="toolbar">
    <button type="button" className="toolbar-btn ql-undo">
      <EditorIcon name="undo" />
    </button>
    <button type="button" className="toolbar-btn ql-redo">
      <EditorIcon name="redo" />
    </button>
    <Divider type="vertical" />
    <button type="button" className="toolbar-btn ql-header" value="1" />
    <button type="button" className="toolbar-btn ql-header" value="2" />
    <button type="button" className="toolbar-btn ql-header" value="3">
      <EditorIcon name="h3" />
    </button>
    <button type="button" className="toolbar-btn ql-header" value="4">
      <EditorIcon name="h4" />
    </button>
    <Divider type="vertical" />
    <button className="toolbar-btn ql-bold" />
    <button className="toolbar-btn ql-italic" />
    <button className="toolbar-btn ql-underline" />
    <Divider type="vertical" />
    <button className="toolbar-btn ql-list" value="ordered" />
    <button className="toolbar-btn ql-list" value="bullet" />
    <Divider type="vertical" />
    <button className="toolbar-btn ql-link" value="bullet" />
    <button
      id="insert-image-button"
      className="toolbar-btn ql-image"
      type="button"
    />
    <button className="toolbar-btn ql-clean" value="bullet" />
  </div>
);
