import * as user from './module/user.api';
import * as template from './module/template.api';
import * as document from './module/document.api';
import * as ai from './module/ai.api';
import * as recipe from './module/recipe.api';
import * as prompt from './module/prompt.api';

const apis = {
  user,
  template,
  document,
  ai,
  recipe,
  prompt,
};

export default apis;
