import React, { useEffect, useState } from 'react';
import ColorHash from 'color-hash';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { Button, Popover, Input, Menu, Progress, Avatar } from 'antd';

import MainMenu from './mainMenu';
// import BottomMenu from './bottomMenu';
import PopoverProfile from './popoverProfile';
import Icon from 'icons';
import PAGES from 'routes/constants';
import { useUser } from 'stores/auth/auth.selector';
import './index.scss';
import { getShortenName } from 'utils';

const colorHash = new ColorHash();

const MenuLayout = () => {
  const location = useLocation();
  const user = useUser();
  const [selectedMenu, setSelectedMenu] = useState([location.pathname]);
  const [selectedProject] = useState('1');
  const [menuItems] = useState([
    {
      key: '1',
      label: 'Personal',
    },
  ]);
  const [openProjectPopover, setOpenProjectPopover] = useState(false);

  useEffect(() => {
    const keys = [];
    if (location.pathname === PAGES.addDocument) {
      keys.push(PAGES.documents);
    }

    if (matchPath(PAGES.templateDetail, location.pathname)) {
      keys.push(PAGES.templates);
    }

    if (matchPath(PAGES.recipeDetail, location.pathname)) {
      keys.push(PAGES.recipes);
    }

    keys.push(location.pathname);
    setSelectedMenu(keys);
  }, [location]);

  const handleClickProject = () => {
    setOpenProjectPopover(false);
  };

  const handleClickAction = ({ key }: any) => {
    console.log(key);
  };

  const handleChangeOpenProjectPopover = (value: boolean) => {
    if (!value) {
      setOpenProjectPopover(value);
    }
  };

  const popoverProject = React.useCallback(() => {
    const actionItems = [
      {
        key: 'view-all',
        label: (
          <Link to="/all-projects" className="d-flex-center-between view-all">
            See all projects <Icon name="chev-right" />
          </Link>
        ),
      },
      {
        key: 'new-project',
        label: '+ New project',
      },
    ];

    return (
      <div className="popover-project__content">
        <div className="popover-project__top">
          <Input className="input-outline" placeholder="Search project" />
        </div>
        <div className="popover-project__body">
          <Menu
            onClick={handleClickProject}
            selectedKeys={[selectedProject]}
            mode="vertical"
            items={menuItems}
          />
        </div>
        <div className="popover-project__action">
          <Menu
            onClick={handleClickAction}
            mode="vertical"
            items={actionItems}
            selectedKeys={[]}
          />
        </div>
      </div>
    );
  }, [menuItems, selectedProject]);

  return (
    <aside className="main-menu">
      <div className="menu-wrapper">
        <div className="menu-top">
          <div className="menu-top__logo">
            <Link to={PAGES.home}>
              <Icon name="homescribe-logo" />
            </Link>
            {/* <Tag>Free</Tag> */}
          </div>

          <div className="menu-top__project">
            <Popover
              open={openProjectPopover}
              placement="bottom"
              content={popoverProject()}
              arrow={false}
              trigger="click"
              overlayClassName="popover-project"
              onOpenChange={handleChangeOpenProjectPopover}
            >
              <Button onClick={() => setOpenProjectPopover(true)}>
                <div className="truncate">
                  <p className="title">PROJECT</p>
                  <p className="truncate project-name">Personal (free)</p>
                </div>
                <div className="icon">
                  <Icon name="caret-up-down" />
                </div>
              </Button>
            </Popover>
          </div>
        </div>

        <nav className="menu-body">
          <div className="menu-body__wrapper">
            <Menu
              mode="vertical"
              items={MainMenu()}
              selectedKeys={selectedMenu}
            />
          </div>
        </nav>

        <div className="menu-extra">
          <div className="menu-extra__credit">
            <div className="d-flex-center-between">
              <div className="menu-extra__credit__title">
                <Icon name="credit" />
                <span>Credits</span>
              </div>
              {/* Something here */}
            </div>

            <div className="menu-extra__credit__progress">
              <Progress percent={45} showInfo={false} strokeWidth={4} />

              <div className="menu-extra__credit__progress__detail">
                FREE to use!
              </div>

              <div className="more-credit">
                <Link to={PAGES.billingSetting}>Upgrade to Pro</Link>
              </div>
            </div>
          </div>
        </div>

        {/* <nav className="menu-bottom">
          <div className="menu-bottom__wrapper">
            <Menu
              mode="vertical"
              items={BottomMenu()}
              selectedKeys={selectedMenu}
            />
          </div>
        </nav> */}

        <div className="quick-search">
          <div className="d-flex-align-center relative">
            <Button className="btn-quick-search btn-outline">
              Quick search
            </Button>
            <div className="shortcut">
              <kbd>⌘K</kbd>
            </div>
          </div>

          <div className="profile-item">
            <Popover
              placement="top"
              content={<PopoverProfile />}
              arrow={false}
              trigger="click"
              overlayClassName="popover-profile"
            >
              {user && (
                <Button className="btn-profile">
                  <Avatar
                    style={{
                      backgroundColor: colorHash.hex(getShortenName(user)),
                      color: '#fff',
                    }}
                  >
                    {getShortenName(user)}
                  </Avatar>
                  <span className="username">
                    {user?.profile?.fullName
                      ? user?.profile?.fullName
                      : `${user?.profile?.firstName} ${user?.profile?.lastName}`}
                  </span>

                  <Icon name="chev-down" />
                </Button>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default MenuLayout;
