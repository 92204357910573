import React from 'react';
import ContentLoader from 'react-content-loader';

const TemplateSkeleton = (props: any) => (
  <ContentLoader
    speed={2}
    width={362}
    height={228}
    viewBox="0 0 362 228"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="16" ry="16" width="362" height="228" />
  </ContentLoader>
);

export default TemplateSkeleton;
