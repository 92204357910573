import { useEffect, useState } from 'react';
import { ChatContent } from 'models/chat-content';
import {
  getChatContents,
  setChatContents,
} from 'services/storages/chatStorage';

const MAX_HISTORIES = 20;

export const useChatContent = () => {
  const [contents, setContents] = useState<ChatContent[]>(
    getChatContents() || [],
  );
  useEffect(() => {
    setChatContents(contents.slice(-MAX_HISTORIES));
  }, [contents]);

  return { contents, setContents };
};
