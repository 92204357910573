enum QUERY_KEYS {
  GET_TEMPLATES = 'get-templates',
  GET_TEMPLATE_DETAIL = 'get-template-detail',
  GET_DOCUMENTS = 'get-documents',
  GET_DOCUMENT_DETAIL = 'get-document-detail',
  GET_RECIPES = 'get-recipes',
  GET_FEATURE_RECIPES = 'get-features-recipes',
  GET_COMMUNITY_RECIPES = 'get-community-recipes',
  GET_RECIPE_DETAIL = 'get-recipe-detail',
  GET_PROMPTS = 'get-prompts',
}

export default QUERY_KEYS;
