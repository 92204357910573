import React from 'react';

import { ReactComponent as Undo } from 'assets/images/editor/undo.svg';
import { ReactComponent as Redo } from 'assets/images/editor/redo.svg';
import { ReactComponent as H1 } from 'assets/images/editor/h1.svg';
import { ReactComponent as H2 } from 'assets/images/editor/h2.svg';
import { ReactComponent as H3 } from 'assets/images/editor/h3.svg';
import { ReactComponent as H4 } from 'assets/images/editor/h4.svg';
import { ReactComponent as Bold } from 'assets/images/editor/bold.svg';
import { ReactComponent as Italic } from 'assets/images/editor/italic.svg';
import { ReactComponent as Underline } from 'assets/images/editor/underline.svg';
import { ReactComponent as Numbered } from 'assets/images/editor/numbered.svg';
import { ReactComponent as Bulleted } from 'assets/images/editor/bulleted.svg';
import { ReactComponent as Link } from 'assets/images/editor/link.svg';
import { ReactComponent as Image } from 'assets/images/editor/image.svg';
import { ReactComponent as Reset } from 'assets/images/editor/reset.svg';

export type IconType =
  | 'undo'
  | 'redo'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'numbered'
  | 'bulleted'
  | 'link'
  | 'image'
  | 'reset';

export enum ICON {
  UNDO = 'undo',
  REDO = 'redo',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  NUMBERED = 'numbered',
  BULLETED = 'bulleted',
  LINK = 'link',
  IMAGE = 'image',
  RESET = 'reset',
}

interface Props {
  name: IconType;
}

const Icons = {
  [ICON.UNDO]: <Undo />,
  [ICON.REDO]: <Redo />,
  [ICON.H1]: <H1 />,
  [ICON.H2]: <H2 />,
  [ICON.H3]: <H3 />,
  [ICON.H4]: <H4 />,
  [ICON.BOLD]: <Bold />,
  [ICON.ITALIC]: <Italic />,
  [ICON.UNDERLINE]: <Underline />,
  [ICON.NUMBERED]: <Numbered />,
  [ICON.BULLETED]: <Bulleted />,
  [ICON.LINK]: <Link />,
  [ICON.IMAGE]: <Image />,
  [ICON.RESET]: <Reset />,
};

function EditorIcon({ name }: Props) {
  return Icons[name] || null;
}

export default React.memo(EditorIcon);
