import React from 'react';
import { Outlet } from 'react-router-dom';

import SettingMenu from 'components/layouts/setting-menu';
import './index.scss';

const SettingLayout = () => {
  return (
    <div className="setting-layout">
      <SettingMenu />

      <div className="setting-content">
        <Outlet />
      </div>
    </div>
  );
};

export default SettingLayout;
