import React, { Component } from 'react';

type MyProps = {
  // using `interface` is also ok
  children: any;
  onClickoutSide: () => void;
};

/**
 * Component that alerts if you click outside of it
 */
export default class ClickOutSide extends Component<MyProps> {
  wrapperRef: React.RefObject<any>;
  constructor(props: any) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event: any) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.onClickoutSide();
    }
  }

  render() {
    return <div ref={this.wrapperRef}>{this.props.children}</div>;
  }
}
